import React, { useContext, useEffect, useState } from 'react';
import arrowLeft from '../../../../../assets/arrowLeft.svg';
import Modal from 'react-modal/lib/components/Modal';
import { useTranslation } from 'react-i18next';
import { useProjectContext } from '../../../../../contexts/projectContext';
import mixpanel from 'mixpanel-browser';
import ZoomAndNavControls from './Zoom And Nav Controls/ZoomAndNavControls';
import ThreeDotsMenu from './Three Dots Menu/ThreeDotsMenu';
import PhotoVideo from './Photo Video/PhotoVideo';
import ContainerAboutPicture from './Container About Picture/ContainerAboutPicture';
import { auth, firestore } from 'src/firebase/config';
import TypeContext from 'src/contexts/typeContext';
import AgencyContext from 'src/contexts/agencyContext';
import { Paths } from 'src/firebase/paths';

export default function ModalPhoto({
	modalPhoto,
	selectedPhoto,
	setSelectedPhoto,
	photos,
	zoomIn,
	zoomOut,
	resetTransform,
	transformWrapperRef,
	goBackButtonModalPhoto,
	albumSelected,
	agencyData,
	saveAsAlbumCover,
	deletePicture,
	loadingCouvertureAlbum,
	enregistree,
	enregistreeProjectCover,
	loadingProjectCover,
	saveAsProjectCover,
	downloadPhotos,
	setPhotos,
	isModeLecture,
	handleModifyLots,
	handleModifyLocations,
}) {
	const { t } = useTranslation();

	const uid = auth.currentUser?.uid;
	const userType = useContext(TypeContext);
	const agencyId = useContext(AgencyContext);
	const [project] = useProjectContext();

	const [like, setLike] = useState(false);
	const [bigScreen, setBigScreen] = useState(!!isModeLecture);

	useEffect(() => {
		if (selectedPhoto) {
			mixpanel.track('Media View', {
				'Project ID': project.id,
				Page: 'albums',
				'Media type': selectedPhoto.type,
			});
		}
	}, [selectedPhoto]);

	const isAgencyMatch = (itemAgency) =>
		itemAgency === undefined
			? project?.creator === agencyData?.uid
			: (userType === 'clients' && itemAgency === 'clients') || itemAgency === agencyData?.uid;

	useEffect(() => {
		if (selectedPhoto && selectedPhoto.feedbacks) {
			if (selectedPhoto.feedbacks.some((item) => item.userId === uid)) {
				setLike(true);
			}
		}
	}, [selectedPhoto]);

	return (
		<Modal isOpen={modalPhoto} className={'modalPhoto'} overlayClassName="overlayModalPhoto" closeTimeoutMS={300}>
			<div style={{ display: 'flex' }}>
				<PhotoVideo
					selectedPhoto={selectedPhoto}
					transformWrapperRef={transformWrapperRef}
					bigScreen={bigScreen}
				/>

				{!isModeLecture && (
					<ContainerAboutPicture
						bigScreen={bigScreen}
						setBigScreen={setBigScreen}
						selectedPhoto={selectedPhoto}
						setSelectedPhoto={setSelectedPhoto}
						setPhotos={setPhotos}
						handleModifyLocations={handleModifyLocations}
					/>
				)}
			</div>

			<div className={'containerBackMenu'}>
				<div className={'goBackButton'} onClick={goBackButtonModalPhoto}>
					<img src={arrowLeft} alt="" className={'icon'} />
				</div>

				<ThreeDotsMenu
					downloadPhotos={downloadPhotos}
					selectedPhoto={selectedPhoto}
					saveAsProjectCover={saveAsProjectCover}
					loadingProjectCover={loadingProjectCover}
					enregistreeProjectCover={enregistreeProjectCover}
					saveAsAlbumCover={saveAsAlbumCover}
					loadingCouvertureAlbum={loadingCouvertureAlbum}
					enregistree={enregistree}
					deletePicture={deletePicture}
					albumSelected={albumSelected}
					isAgencyMatch={isAgencyMatch}
					isModeLecture={isModeLecture}
					bigScreen={bigScreen}
					handleModifyLots={handleModifyLots}
					handleModifyLocations={handleModifyLocations}
				/>
			</div>

			{selectedPhoto && selectedPhoto.type === 'video' && <div style={{ height: 'calc(100vh - 90px)' }} />}

			<ZoomAndNavControls
				selectedPhoto={selectedPhoto}
				setSelectedPhoto={setSelectedPhoto}
				photos={photos}
				zoomIn={zoomIn}
				zoomOut={zoomOut}
				resetTransform={resetTransform}
				bigScreen={bigScreen}
			/>
		</Modal>
	);
}
