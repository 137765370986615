import React, { useContext, useEffect, useState } from 'react';
import { deleteUserInProject } from '../../../../useful/UsefulForProject';
import AgencyContext from '../../../../contexts/agencyContext';
import { useTranslation } from 'react-i18next';
import { firestore, functions } from '../../../../firebase/config';
import { createdByConstructor } from '../../../Fiche Projet/utils';
import AgencyDataContext from '../../../../contexts/agencyDataContext';
import { httpsCallable } from 'firebase/functions';
import { cleanNaNValues } from 'src/useful/utils';

export default function ProjetItem({ id, projectData, projects, uid, collaboratorName, collaboratorData }) {
	const { t } = useTranslation();

	const agency = useContext(AgencyContext);
	const agencyData = useContext(AgencyDataContext);

	const [numberCollab, setNumberCollab] = useState(0);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		firestore
			.collection(`projects/${id}/accounts`)
			.where('type', '==', 'collaborators')
			.get()
			.then((querySnapshot) => {
				if (querySnapshot) {
					setNumberCollab(querySnapshot.size);
				}
			});
	}, [id]);

	return (
		<>
			{projectData && (
				<div className={'cardProjetListItem'}>
					<img src={projectData.imgUrl} alt="" className={'imageProjet'} />
					<div style={{ width: 405 }}>
						<p className={'titleProjet'}>{projectData.name}</p>
						<p className={'subtitleProjet'}>
							{numberCollab} {t('messaging.little_collab')}
							{numberCollab > 1 && 's'}
						</p>
					</div>
					{!projects.includes(id) ? (
						<div
							className={'buttonAjouterVert'}
							onClick={() => {
								if (!loading) {
									setLoading(true);
									setNumberCollab((v) => v + 1);
									const call = httpsCallable(functions, 'addUserToProject');
									call(
										cleanNaNValues({
											createdBy: createdByConstructor(uid, 'collaborator', agency),
											projectId: id,
											parent: agencyData,
											data: collaboratorData,
											senderName: collaboratorName,
											projectName: projectData.name,
											projectImgUrl: projectData.imgUrl,
											withoutNotification: true,
										})
									).then((r) => {
										setLoading(false);
									});
								}
							}}>
							<p>{loading ? t('common.loading') : t('common.add')}</p>
						</div>
					) : (
						<div
							className={'buttonAjouterRouge'}
							onClick={async () => {
								if (
									!loading &&
									window.confirm(
										`${t('modal_accounts.sure_you_want_to_delete')} ${collaboratorName} ${t(
											'modal_accounts.from_this_project'
										)}`
									)
								) {
									setLoading(true);
									setNumberCollab((v) => v - 1);
									await deleteUserInProject(uid, 'collaborators', agency, id, agency);
									setLoading(false);
								}
							}}>
							<p>{loading ? t('common.loading') : t('common.delete')}</p>
						</div>
					)}
				</div>
			)}
		</>
	);
}
