import React from 'react';
import { useTranslation } from 'react-i18next';
import { useProjectContext } from 'src/contexts/projectContext';
import './_printPagePhotos.scss';
import mappinBlue from 'src/assets/mappinBlue.svg';
import { useProjectLocations } from 'src/contexts/projectLocationsContext';
import { formatDateString } from 'src/useful/date';
import { chunkArray } from 'src/useful/utils';

export default function PrintPagePhotos({ pictures, visitDate, locationId }) {
	const { t, i18n } = useTranslation();

	const [project] = useProjectContext();
	const [locations] = useProjectLocations();

	const locationName = locations?.find((location) => location.id === locationId)?.name ?? '';

	return (
		<div className={'printPagePhotos'}>
			<div className={'statusBar'} style={{ backgroundColor: 'rgba(28, 87, 221, 0.1)', color: '#1C57DD' }}>
				<span style={{ width: 300, display: 'flex', alignItems: 'center' }}>
					<img src={mappinBlue} alt="" className={'mappinBlue'} />{' '}
					{locations?.find((location) => location.id === locationId)?.name ?? t('All site pictures')}
				</span>
				<span>{formatDateString(visitDate, i18n.language)}</span>
				<span style={{ width: 300, display: 'flex', justifyContent: 'flex-end' }}>{project.name}</span>
			</div>

			<div className={'containerPictures'}>
				{chunkArray(pictures, 2).map((chunk, index) => (
					<div className={'row'} key={'row' + index}>
						{chunk?.map((picture) => (
							<div className={'containerPicture'} key={picture.id}>
								<span className={'hashtag'}>
									#{picture.globalIndex + 1}
									{locationName ? ' • ' + locationName : ''}
								</span>
								<img src={picture.url} alt="" className={'picture'} />
								{picture.comments?.find((comment) => comment.userId === picture.createdBy?.userId)
									?.content?.length > 0 && <p className={'legend'}>{t('translation.legend')}</p>}
								<p className={'corpsLegend'}>
									{
										picture.comments?.find(
											(comment) => comment.userId === picture.createdBy?.userId
										)?.content
									}
								</p>
							</div>
						))}
					</div>
				))}
			</div>
		</div>
	);
}
