import { changeSortString } from '../../../../useful/UsefulFunctions';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { auth, firestore } from '../../../../firebase/config';
import ModalTyping from '../../../../components/ModalTyping/ModalTyping';
import AgencyContext from '../../../../contexts/agencyContext';
import DragComponent from '../../../../components/DragComponent';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import bigEditIcon from '../../../../assets/bigEditIcon.svg';
import ModalModifPosteDepense from './ModalModifPosteDepense';
import plusSquare from '../../../../assets/plusSquare.svg';
import ExpenseItem from './ExpenseItem';
import trashIcon from '../../../../assets/trashIcon.svg';
import { useTranslation } from 'react-i18next';
import '../_finances.scss';
import mixpanel from 'mixpanel-browser';
import { useClientsContext } from 'src/contexts/clientsContext';

export default function Category({
	projectId,
	categName,
	categIndex,
	expenses,
	setExpenses,
	ttc,
	mandataire,
	reorganise,
	setGlobalPaid,
	setGlobalEstimate,
	research,
	setModalModifPosteDepense,
	setSelectedExpenseData,
	type,
	isAdministrator,
	isModeLecture,
	currency,
	canModify,
}) {
	const { t } = useTranslation();

	const agency = useContext(AgencyContext);
	const uid = auth.currentUser?.uid;

	const [name, setName] = useState(categName);
	const ref = useRef();
	const [modalRename, setModalRename] = useState(false);
	const [modalNewExpense, setModalNewExpense] = useState(false);

	useEffect(() => {
		setName(categName);
	}, [categName]);

	async function newExpense(id, name, paid, estimate, isConfidential) {
		const data = {
			name: name ?? t('modal_edit_expense_item.expense_item'),
			estimate: estimate ?? 0,
			paid: paid ?? 0,
			date: new Date().toISOString(),
			uid,
			agency,
			//creator: agency,
			id,
			category: categName,
			isConfidential: isConfidential ?? false,
		};
		setExpenses((oldValue) => {
			const newValue = [...oldValue];
			newValue[categIndex] = [data, ...newValue[categIndex]];
			return newValue;
		});
		ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
		await firestore.doc(`projects/${projectId}/expenses/${id}`).set(data);

		mixpanel.track('Expense Created', {
			'Project ID': projectId,
			Page: 'finances',
		});
	}

	async function rename(newName) {
		const oldName = name;
		const prefix = oldName.indexOf('*\\') > -1 ? oldName.slice(0, oldName.indexOf('*\\') + 2) : '';
		setName(prefix + newName);
		setExpenses((oldValue) => {
			const newValue = [...oldValue];
			newValue[categIndex][0].category = prefix + newName;
			return newValue;
		});
		for (const item of expenses) {
			if (item.category !== newName) {
				await firestore.doc(`projects/${projectId}/expenses/${item.id}`).update({ category: prefix + newName });
			}
		}
	}

	async function deleteCategory() {
		if (window.confirm(t('finances.sure_you_want_to_delete_this_category'))) {
			setExpenses((oldValue) => {
				const newValue = [...oldValue];
				newValue.splice(categIndex, 1);
				return newValue;
			});
			for (const expense of expenses) {
				await firestore.doc(`projects/${projectId}/expenses/${expense.id}`).delete();
			}
		}
	}

	const SortableItem = SortableElement(({ expenseData, expenseIndex }) => (
		<ExpenseItem
			type={type}
			expenseData={expenseData}
			ttc={ttc}
			mandataire={mandataire}
			research={research}
			setSelectedExpenseData={setSelectedExpenseData}
			setModalModifPosteDepense={setModalModifPosteDepense}
			categIndex={categIndex}
			expenseIndex={expenseIndex}
			isModeLecture={isModeLecture}
			currency={currency}
			canModify={canModify}
		/>
	));

	const SortableList = SortableContainer(({ items }) => (
		<div className={'expensesList'} style={{ height: reorganise ? 0 : null }}>
			{items.map((value, index) => (
				<SortableItem
					key={`item-${index}`}
					index={index}
					expenseData={value}
					expenseIndex={index}
					disabled={research}
				/>
			))}
		</div>
	));

	return (
		<div className={'categoryFinances'}>
			<div className={'headerCategory'}>
				{reorganise && (
					<div style={{ marginTop: 22 }}>
						<DragComponent />
					</div>
				)}

				<p className={'titleCategory'}>{name ? changeSortString(name) : t('common.without_title')}</p>

				{reorganise ? null : canModify ? (
					<div className={'containerButtonsCategory'}>
						<div onClick={() => setModalRename(true)} className={'hover buttonCategory'}>
							<img src={bigEditIcon} alt="iconPlus" />
							<p className={'title'}>{t('common.edit')}</p>
						</div>

						{((isAdministrator && mandataire) || expenses.every((item) => !item.isConfidential)) && (
							<div onClick={async () => await deleteCategory()} className={'buttonCategory hover'}>
								<img src={trashIcon} alt="iconPlus" />
								<p className={'title'}>{t('common.delete')}</p>
							</div>
						)}

						<div onClick={() => setModalNewExpense(true)} className={'buttonCategory hover'}>
							<img src={plusSquare} alt="iconPlus" />
							<p className={'title'}>{t('modal_edit_expense_item.expense_item')}</p>
						</div>
					</div>
				) : null}
			</div>

			{!reorganise && !expenses.filter((item) => !item.dummy).length && (
				<p className={'emptyCateg'}>{t('finances.empty_categ')}</p>
			)}

			{expenses && (
				<SortableList
					pressDelay={200}
					axis={'xy'}
					items={expenses}
					onSortEnd={async ({ oldIndex, newIndex }) => {
						const items = [...expenses];
						const [reorderedItem] = items.splice(oldIndex, 1);
						items.splice(newIndex, 0, reorderedItem);
						setExpenses((oldValue) => {
							const newValue = [...oldValue];
							newValue[categIndex] = items;
							return newValue;
						});
						for (const item of items.slice(0, newIndex + 1).reverse()) {
							if (!item.dummy) {
								await firestore
									.doc(`projects/${projectId}/expenses/${item.id}`)
									.update({ date: new Date().toISOString() });
							}
						}
					}}
				/>
			)}

			<ModalTyping
				modalTyping={modalRename}
				setModalTyping={setModalRename}
				title={t('finances.category_name')}
				func={rename}
				defaultValue={changeSortString(name)}
				placeholder={t('finances.category_name')}
			/>

			<ModalModifPosteDepense
				isNew={true}
				modalModifPosteDepense={modalNewExpense}
				setModalModifPosteDepense={setModalNewExpense}
				projectId={projectId}
				ttc={ttc}
				mandataire={mandataire}
				setExpenses={setExpenses}
				categIndex={categIndex}
				setGlobalPaid={setGlobalPaid}
				setGlobalEstimate={setGlobalEstimate}
				createExpense={newExpense}
				expenseData={{
					name: t('modal_edit_expense_item.expense_item'),
					date: new Date().toISOString(),
					paid: 0,
					estimate: 0,
					dummy: false,
					id: '',
					category: categName,
					isConfidential: false,
				}}
				currency={currency}
				isModeLecture={isModeLecture}
				canModify={canModify}
			/>
		</div>
	);
}
