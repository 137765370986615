import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import '../../_photos.scss';

export function DropZonePhotos({ isActive, handleDownload, isVisit, wasEmpty, alreadyNotified }) {
	const { t } = useTranslation();

	const onDrop = useCallback(
		async (acceptedFiles) => {
			handleDownload(acceptedFiles, wasEmpty, alreadyNotified);
		},
		[wasEmpty, alreadyNotified]
	);
	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

	return (
		<div
			{...getRootProps()}
			className={'dropZoneAlbum'}
			style={{
				width: isVisit ? 'calc(100vw - 670px)' : 'calc(100vw - 235px)',
				zIndex: isActive ? 1 : 0,
				borderColor: isDragActive ? '#8491e0' : 'rgba(115,97,97,0)',
				backgroundColor: isDragActive ? 'rgba(0,104,255,0.12)' : 'rgba(250,0,0,0)',
			}}>
			<input
				{...getInputProps()}
				accept="image/*,video/*,.heic, .heif"
				multiple={true}
				disabled={!isDragActive}
			/>
			<p className={'textDropZoneAlbum'} style={{ opacity: isDragActive ? 1 : 0 }}>
				{t('photos.drop_zone_album_1')} <br /> {t('photos.drop_zone_album_2')}
			</p>
		</div>
	);
}
