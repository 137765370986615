import React, { useContext, useState } from 'react';
import Modal from 'react-modal/lib/components/Modal';
import xmark from 'src/assets/xmark.svg';
import screenInfos from 'src/assets/screenInfos.svg';
import { dateString } from 'src/useful/UsefulFunctions';
import { useTranslation } from 'react-i18next';
import ModalNewView from './ModalNewView';
import pen from 'src/assets/pen.png';
import TypeContext from 'src/contexts/typeContext';
import AgencyContext from 'src/contexts/agencyContext';

export default function ModalViewMatterport({
	modalViewMatterport,
	setModalViewMatterport,
	selectedView,
	setPhotos,
	projectId,
	albumData,
}) {
	const { t } = useTranslation();

	const type = useContext(TypeContext);
	const agencyId = useContext(AgencyContext);

	const [bigScreen, setBigScreen] = useState(false);
	const [modalModif, setModalModif] = useState(false);

	return (
		<Modal
			isOpen={modalViewMatterport}
			className={'modalViewMatterport'}
			overlayClassName={'overlayModalAlbumPhoto'}
			closeTimeoutMS={300}>
			<div className={'leftPart'} style={{ width: bigScreen ? '100vw' : 'calc(100vw - 350px)', zIndex: 2 }}>
				<div
					style={{
						display: 'flex',
						position: 'absolute',
						right: 0,
						backgroundColor: '#fff',
						width: 87,
						borderRadius: 20,
						height: 40,
						marginTop: 20,
						marginRight: 20,
					}}>
					<img
						onClick={() => {
							if (bigScreen) {
								setBigScreen(false);
							} else {
								setBigScreen(true);
							}
						}}
						src={screenInfos}
						className={'hover'}
						alt=""
						style={{
							width: 25,
							marginLeft: 12,
							opacity: 0.7,
							cursor: 'pointer',
						}}
					/>
					<img
						style={{
							width: 22,
							marginLeft: 15,
							opacity: 0.7,
							cursor: 'pointer',
						}}
						src={xmark}
						alt=""
						className={'hover'}
						onClick={() => setModalViewMatterport(false)}
					/>
				</div>
				<iframe
					width="100%"
					height="100%"
					src={selectedView?.url}
					frameBorder="0"
					allowFullScreen
					allow="xr-spatial-tracking"
				/>
			</div>

			<div className={'rightPart'} style={{ zIndex: 1 }}>
				<div>
					<img src={selectedView?.thumbnailUrl} alt="" className={'matterportImg'} style={{ zIndex: 10 }} />
				</div>

				<div style={{ display: 'flex', alignItems: 'center', flex: 1, zIndex: 1, flexDirection: 'column' }}>
					<div
						style={{ display: 'flex', cursor: 'pointer' }}
						onClick={() =>
							setModalModif(
								agencyId === selectedView?.createdBy?.agencyId ||
									(type === 'clients' && selectedView?.createdBy?.userType === 'client')
							)
						}>
						<p style={{ fontWeight: 600, fontSize: 16, marginLeft: 23 }}>{selectedView?.name}</p>
						{(agencyId === selectedView?.createdBy?.agencyId ||
							(type === 'clients' && selectedView?.createdBy?.userType === 'client')) && (
							<img
								alt={'modifier'}
								src={pen}
								style={{
									marginLeft: 3,
									marginTop: 2,
									width: 23,
									objectFit: 'contain',
									opacity: 0.6,
								}}
							/>
						)}
					</div>
					<p
						style={{
							marginTop: 0,
							fontWeight: 600,
							fontSize: 12,
							opacity: 0.7,
						}}>
						{dateString(t, selectedView?.orderDate, false, false, false)}
					</p>

					<div className={'password'}>Mot de passe : {selectedView?.password}</div>
				</div>
			</div>

			<ModalNewView
				setPhotos={setPhotos}
				viewData={selectedView}
				modalNewView={modalModif}
				setModalNewView={setModalModif}
				isModif={true}
				projectId={projectId}
				albumData={albumData}
				setModalView={setModalViewMatterport}
			/>
		</Modal>
	);
}
