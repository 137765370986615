import React, { useEffect } from 'react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { transformString } from 'src/useful/UsefulFunctions';
import { noLevelId } from '../../Settings Projet/Sub Pages/OnSiteLocations';
import ItemLevel from './ItemLevel';
import { useProjectLocations } from 'src/contexts/projectLocationsContext';
import PropTypes from 'prop-types';
import styles from './LocationsDropDown.module.css';

LocationsDropDown.propTypes = {
	selectedLocations: PropTypes.array.isRequired,
	handleChange: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	handleModifyLocations: PropTypes.func.isRequired,
};

export default function LocationsDropDown({ selectedLocations, handleChange, onClose, handleModifyLocations }) {
	const { t } = useTranslation();

	const [locations] = useProjectLocations();

	const [research, setResearch] = useState('');

	const containerRef = useRef(null);
	const inputRef = useRef(null);

	const groupedLocations =
		locations
			?.filter((location) => location.type !== 'level')
			?.reduce((groups, location) => {
				const level = locations.find((l) => l.id === location.levelId);
				const key = level ? (level.id ?? noLevelId) : noLevelId;

				if (!groups[key]) {
					groups[key] = {
						id: key,
						name:
							key === noLevelId
								? t('translation.noLevel')
								: locations.find((l) => l.id === key)?.name || '',
						locations: [],
					};
				}

				groups[key].locations = [...groups[key].locations, location];

				return groups;
			}, {}) || {};

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			const handleClickOutside = (e) => {
				if (containerRef.current && !containerRef.current.contains(e.target)) {
					onClose();
				}
			};

			document.addEventListener('click', handleClickOutside);
			return () => document.removeEventListener('click', handleClickOutside);
		}, 0);

		return () => clearTimeout(timeoutId);
	}, [containerRef, onClose]);

	return (
		<div className={styles.locationsDropDown} ref={containerRef}>
			<div className={styles.research}>
				<input
					ref={inputRef}
					type={'text'}
					placeholder={t('translation.research')}
					value={research}
					onChange={(e) => setResearch(e.target.value)}
				/>
			</div>

			<div className={styles.content}>
				<div className={styles.header}>
					<p className={styles.subtitle}>{t('translation.levels')}</p>
					<p className={styles.editButton} onClick={handleModifyLocations}>
						{t('translation.modify')}
					</p>
				</div>

				{locations
					?.filter(
						(item) =>
							item.type === 'level' &&
							(transformString(item.name).includes(transformString(research)) ||
								groupedLocations[item.id]?.locations?.some((location) =>
									transformString(location.name).includes(transformString(research))
								))
					)
					?.map((level) => (
						<ItemLevel
							key={level.id}
							level={level}
							locations={groupedLocations[level.id]?.locations}
							selectedLocations={selectedLocations}
							setSelectedLocations={handleChange}
							research={research}
						/>
					))}

				{(transformString(t('translation.noLevel')).includes(transformString(research)) ||
					groupedLocations[noLevelId]?.locations?.some((location) =>
						transformString(location.name).includes(transformString(research))
					)) && (
					<ItemLevel
						key={noLevelId}
						level={{ id: noLevelId, name: t('translation.noLevel') }}
						locations={groupedLocations[noLevelId]?.locations}
						selectedLocations={selectedLocations}
						setSelectedLocations={handleChange}
						research={research}
					/>
				)}
			</div>
		</div>
	);
}
