import React, { useContext } from 'react';
import './_pageProjectSynthesisReport.scss';
import { useTranslation } from 'react-i18next';
import AuthDataContext from '../../../../contexts/authDataContext';
import { MISSIONS_STATUS } from 'src/pages/Fiche Projet/Tickets/data';

export default function PageProjectSynthesisReport({ project, missions, collaborators }) {
	const { t, i18n } = useTranslation();

	const authData = useContext(AuthDataContext);
	const isAdmin = authData?.subtype === 'administrator';

	const dateAvecMajuscule = capitalizeFirstLetter(
		new Date().toLocaleDateString(i18n.language, {
			weekday: 'long',
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		})
	);

	return (
		<div className={'singlePage'}>
			<div className={'headerPage'}>
				<div className={'generalProjectInfos'}>
					<span className={'dayDate'}>{dateAvecMajuscule}</span>
					<span className={'projectName'}>{project.name}</span>
					<span className={'itemGeneralInfos'}>
						<span className={'bold'}>Maître d'ouvrage : </span>
						{project.clientName?.length > 0 ? project.clientName : t('translation.notFilled')}
					</span>
					<span className={'itemGeneralInfos'}>
						<span className={'bold'}>Assistance Maître d'ouvrage : </span>
						{project.assistClientName?.length > 0 ? project.assistClientName : t('translation.notFilled')}
					</span>
					<span className={'itemGeneralInfos'} style={{ whiteSpace: 'pre-line' }}>
						<span className={'bold'}>Coordonnées de la MOA : </span>
						{project.clientContactDetails?.length > 0
							? project.clientContactDetails
							: t('translation.notFilled')}
					</span>
					<span className={'itemGeneralInfos'}>
						<span className={'bold'}>Collaborateurs associés : </span>
						{collaborators.length > 0
							? collaborators
									?.map((collaborator) => collaborator.surname + ' ' + collaborator.name)
									?.join(' • ')
							: t('translation.notFilled')}
					</span>
				</div>
				<img src={project.imgUrl} alt="" className={'projectPictureReport'} />
			</div>

			<div className={'largeBar'} />

			<div style={{ display: 'flex', width: '100%', gap: 20 }}>
				<div
					style={{
						display: 'flex',
						gap: 10,
						alignItems: 'flex-start',
						flexDirection: 'column',
						width: '100%',
					}}>
					<div className={'infosBlock'}>
						<span className={'titleInfosBlock'}>{t('translation.companyMission')}</span>
						<span>
							{project.projectDescription?.length > 0
								? project.projectDescription
								: t('translation.notFilled')}
						</span>
					</div>
					<div className={'infosBlock'}>
						<span className={'titleInfosBlock'}>Travaux</span>
						<span>
							<span style={{ fontWeight: '600' }}>{t('translation.workAmount')} :</span>{' '}
							{project.workAmount?.length > 0 ? project.workAmount : t('translation.notFilled')}
						</span>
						<span>
							<span style={{ fontWeight: '600' }}>{t('translation.receptionDate')} :</span>{' '}
							{project.receptionDate?.length > 0 ? project.receptionDate : t('translation.notFilled')}
						</span>
						<span>
							<span style={{ fontWeight: '600' }}>{t('translation.worksDuration')} :</span>{' '}
							{project.worksDuration?.length > 0 ? project.worksDuration : t('translation.notFilled')}
							{project.workDurationModifiedAt && (
								<span style={{ opacity: 0.7 }}>
									{' '}
									• Màj il y a {getDaysBetweenDates(project.workDurationModifiedAt, new Date())} jours
								</span>
							)}
						</span>
					</div>
				</div>

				{isAdmin && (
					<div
						style={{
							display: 'flex',
							gap: 10,
							alignItems: 'flex-start',
							flexDirection: 'column',
							width: '100%',
						}}>
						<div className={'infosBlock'}>
							<span className={'titleInfosBlock'}>{t('translation.risk')}</span>
							<span>{project.risk?.length > 0 ? project.risk : t('translation.notFilled')}</span>
						</div>
						<div className={'infosBlock'}>
							<span className={'titleInfosBlock'}>Contrat & Honoraires</span>
							<span>
								<span style={{ fontWeight: '600' }}>{t('translation.estimatedFees')} :</span>{' '}
								{project.estimatedFees?.length > 0 ? project.estimatedFees : t('translation.notFilled')}
							</span>
							<span>
								<span style={{ fontWeight: '600' }}>{t('translation.billedFees')} :</span>{' '}
								{project.billedFees?.length > 0 ? project.billedFees : t('translation.notFilled')}{' '}
								{project.billedFeesModifiedAt && (
									<span style={{ opacity: 0.7 }}>
										• Màj il y a {getDaysBetweenDates(project.billedFeesModifiedAt, new Date())}{' '}
										jours
									</span>
								)}
							</span>
							<span style={{ whiteSpace: 'pre-line' }}>
								<span style={{ fontWeight: '600' }}>{t('translation.contractInformations')} :</span>{' '}
								{project.contractSignatureDate?.length > 0
									? project.contractSignatureDate
									: t('translation.notFilled')}
							</span>
						</div>
					</div>
				)}
			</div>

			<div className="tables-container">
				<div className="table-wrapper">
					<table className="table-custom-widths">
						<colgroup>
							<col className="col-large" />
							<col className="col-small" />
							<col className="col-small" />
							<col className="col-small" />
						</colgroup>
						<thead>
							<tr style={{ color: '#000' }}>
								<th>Missions</th>
								<th style={{ textAlign: 'center' }}>Progress</th>
								<th style={{ textAlign: 'center' }}>Début</th>
								<th style={{ textAlign: 'center' }}>Achèvement</th>
								<th style={{ textAlign: 'center' }}>Dernière Màj</th>
							</tr>
						</thead>
						<tbody>
							{missions.length > 0 ? (
								missions.map((item, index) => (
									<tr key={index}>
										<td
											style={{
												color:
													calculateMissionProgress(item) === 100
														? '#3CA504'
														: calculateMissionProgress(item) > 1
															? '#1C57DD'
															: null,
												fontWeight:
													calculateMissionProgress(item) === 100
														? '700'
														: calculateMissionProgress(item) > 1
															? '700'
															: null,
											}}>
											{item.name}
										</td>
										<td
											style={{
												textAlign: 'center',
												color:
													calculateMissionProgress(item) === 100
														? '#3CA504'
														: calculateMissionProgress(item) > 1
															? '#1C57DD'
															: null,
												fontWeight:
													calculateMissionProgress(item) === 100
														? '700'
														: calculateMissionProgress(item) > 1
															? '700'
															: null,
											}}>
											{Math.round(calculateMissionProgress(item))} %
										</td>
										<td
											style={{
												textAlign: 'center',
												color:
													calculateMissionProgress(item) === 100
														? '#3CA504'
														: calculateMissionProgress(item) > 1
															? '#1C57DD'
															: null,
												fontWeight:
													calculateMissionProgress(item) === 100
														? '700'
														: calculateMissionProgress(item) > 1
															? '700'
															: null,
											}}>
											{item.properties?.issueAt
												? new Date(item.properties?.issueAt).toLocaleDateString(i18n.language, {
														day: 'numeric',
														month: 'short',
														year: 'numeric',
													})
												: ''}
										</td>
										<td
											style={{
												textAlign: 'center',
												color:
													calculateMissionProgress(item) === 100
														? '#3CA504'
														: calculateMissionProgress(item) > 1
															? '#1C57DD'
															: null,
												fontWeight:
													calculateMissionProgress(item) === 100
														? '700'
														: calculateMissionProgress(item) > 1
															? '700'
															: null,
											}}>
											{item.properties?.dueAt
												? new Date(item.properties?.dueAt).toLocaleDateString(i18n.language, {
														day: 'numeric',
														month: 'short',
														year: 'numeric',
													})
												: ''}
										</td>

										<td
											style={{
												textAlign: 'center',
												color:
													calculateMissionProgress(item) === 100
														? '#3CA504'
														: calculateMissionProgress(item) > 1
															? '#1C57DD'
															: null,
												fontWeight:
													calculateMissionProgress(item) === 100
														? '700'
														: calculateMissionProgress(item) > 1
															? '700'
															: null,
											}}>
											{calculateMissionProgress(item) === 100
												? 'Achevé'
												: calculateMissionProgress(item) > 0 &&
													`${getDaysBetweenDates(item.modifiedAt, new Date())} jour${
														getDaysBetweenDates(item.modifiedAt, new Date()) > 1 ? 's' : ''
													}`}
										</td>
									</tr>
								))
							) : (
								<tr>
									<td colSpan={4} style={{ textAlign: 'center' }}>
										<span>Aucune mission déclarée pour ce projet.</span>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}

function selectRiskColor(risk) {
	switch (risk) {
		case 1:
			return '#3CA504';
		case 2:
			return '#ff5900';
		case 3:
			return '#7b00ff';
		case 4:
			return '#ff0000';
	}
}

function selectRiskText(risk) {
	switch (risk) {
		case 1:
			return 'Faible';
		case 2:
			return 'Modéré';
		case 3:
			return 'Fort';
		case 4:
			return 'Intense';
	}
}

function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

function calculateMissionProgress(mission) {
	if (!mission.todos || mission.todos?.length === 0)
		return mission.missionStatus === MISSIONS_STATUS.finished ? 100 : 0;

	const percentage =
		(mission.todos?.reduce(
			(acc, todo) =>
				acc +
				(todo.completed
					? 1
					: Math.min(todo.subTodos.filter((subTodo) => subTodo.completed).length) /
						(todo.subTodos.length + 1)),
			0
		) /
			mission.todos?.length) *
		100;

	return mission.missionStatus === MISSIONS_STATUS.finished ? 100 : Math.min(percentage, 99);
}

function getDaysBetweenDates(date1, date2) {
	const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
	const firstDate = new Date(date1);
	const secondDate = new Date(date2);
	const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
	return diffDays;
}
