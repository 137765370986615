import React, { useEffect, useMemo, useRef, useState } from 'react';
import './displayPicturesVisit.scss';
import LocationItemVisit from './Location Item Visit/LocationItemVisit';
import { useTranslation } from 'react-i18next';
import { useProjectLocations } from '../../../../../contexts/projectLocationsContext';
import LocationsList from './Locations List/LocationsList';
import { firestore } from 'src/firebase/config';
import { joinPaths } from 'src/firebase/utils';
import { Paths } from 'src/firebase/paths';
import { useProjectContext } from 'src/contexts/projectContext';
import useDataInformations from 'src/pages/Fiche Projet/useDataInformations';

export default function DisplayPicturesVisit({
	album,
	agencyData,
	isSelectOption,
	setSelectedPhoto,
	opacityPhoto,
	partnerAgencies,
	selectedPhotos,
	setDropZoneIsActive,
	setModalPhoto,
	setSelectedPhotos,
	handleDownload,
	groupedPictures,
}) {
	const { t } = useTranslation();

	const [locations] = useProjectLocations();
	const [project] = useProjectContext();
	const { canModify } = useDataInformations(album);

	const [visibleIndex, setVisibleIndex] = useState(0);

	/////////////////////////////////////// GROUPEMENT DES PHOTOS ///////////////////////////////////////

	// Créer un objet pour stocker les refs pour chaque location
	const locationRefs = useRef({});

	// Fonction pour scroller vers une location
	const scrollToLocation = (index) => {
		locationRefs.current[index]?.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
		});
	};

	/////////////////////////////////////// TEXT AREA ///////////////////////////////////////

	const [generalDescription, setGeneralDescription] = useState(album?.description);

	const textareaRef = useRef(null);
	const [textAreaIsFocus, setTextAreaIsFocus] = useState(false);

	const adjustHeight = () => {
		const textarea = textareaRef.current;
		if (textarea) {
			// Réinitialiser la hauteur à 0 pour recalculer correctement
			textarea.style.height = 'auto';
			// Définir la nouvelle hauteur basée sur le contenu
			textarea.style.height = textarea.scrollHeight + 'px';
		}
	};

	useEffect(() => {
		// Ajuster la hauteur initiale
		adjustHeight();
	}, []);

	/////////////////////////////////////// LOCATION VISIBLE ///////////////////////////////////////

	const observerRef = useRef(null);

	useEffect(() => {
		// Nettoyage de l'observer précédent
		if (observerRef.current) {
			observerRef.current.disconnect();
		}

		// Configuration de l'Intersection Observer avec des options plus appropriées
		observerRef.current = new IntersectionObserver(
			(entries) => {
				// Trouver l'entrée avec la plus grande intersection ratio
				const maxEntry = entries.reduce((max, entry) => {
					return entry.intersectionRatio > max.intersectionRatio ? entry : max;
				}, entries[0]);

				if (maxEntry && maxEntry.intersectionRatio > 0) {
					const index = parseInt(maxEntry.target.dataset.index);
					setVisibleIndex(index);
				}
			},
			{
				// Options ajustées pour une meilleure détection
				root: null, // viewport
				rootMargin: '-10% 0px -70% 0px', // Réduit la zone de détection
				threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0], // Plus de points de détection
			}
		);

		// Observer chaque section avec un délai pour s'assurer que les refs sont établies
		setTimeout(() => {
			Object.entries(locationRefs.current).forEach(([index, ref]) => {
				if (ref) {
					observerRef.current.observe(ref);
				}
			});
		}, 100);

		// Cleanup
		return () => {
			if (observerRef.current) {
				observerRef.current.disconnect();
			}
		};
	}, [groupedPictures]);

	const onBlur = () => {
		setTextAreaIsFocus(false);
		if (generalDescription !== album?.description) {
			firestore.doc(joinPaths(Paths.PROJECTS, project.id, Paths.MEDIAS, album.id)).update({
				description: generalDescription,
			});
		}
	};

	return (
		<div className="displayPicturesVisit">
			<div className="headerVisit">
				<div className="descriptionPart" style={{ marginLeft: 30 }}>
					<span className="titleDescription">{t('translation.generalDescription')}</span>
					<textarea
						disabled={!canModify}
						onFocus={() => setTextAreaIsFocus(true)}
						onBlur={onBlur}
						style={{ opacity: textAreaIsFocus ? 1 : 0.5 }}
						ref={textareaRef}
						value={generalDescription}
						onChange={(e) => {
							setGeneralDescription(e.target.value);
							adjustHeight();
						}}
						className={'generalDescription'}
						placeholder={t('Write your general description here...')}
					/>
				</div>
			</div>

			{groupedPictures?.some((locationGroup) => locationGroup.locationId !== 'no_location') && (
				<LocationsList
					groupedPictures={groupedPictures}
					scrollToLocation={scrollToLocation}
					visibleIndex={visibleIndex}
				/>
			)}

			{groupedPictures.map((locationGroup, index) => (
				<div key={index} ref={(el) => (locationRefs.current[index] = el)} data-index={index}>
					<LocationItemVisit
						pictures={locationGroup.allPictures}
						index={index}
						selectedPhotos={selectedPhotos}
						setSelectedPhotos={setSelectedPhotos}
						setSelectedPhoto={setSelectedPhoto}
						isSelectOption={isSelectOption}
						setModalPhoto={setModalPhoto}
						setDropZoneIsActive={setDropZoneIsActive}
						opacityPhoto={opacityPhoto}
						agencyData={agencyData}
						partnerAgencies={partnerAgencies}
						handleDownload={handleDownload}
						locationName={
							locations?.find((location) => location.id === locationGroup.locationId)?.name ??
							t('All site pictures')
						}
						locationId={locationGroup.locationId}
						album={album}
					/>
				</div>
			))}
			<div style={{ height: 450 }} />
		</div>
	);
}
