import React, { useContext, useEffect, useState } from 'react';
import Progression from './Progression/Progression';
import Finances from './Finances/Finances';
import PhotosPage from './Photos/PhotosPage';
import MenuProjet from '../../components/Main/MenuProjet';
import AgencyContext from '../../contexts/agencyContext';
import TypeContext from '../../contexts/typeContext';
import chargement from '../../assets/chargement.png';
import settingsIcon from '../../assets/settingsIcon.png';
import { useTranslation } from 'react-i18next';
import Documents from './Documents/Documents';
import SettingsProjet from './Settings Projet/SettingsProjet';
import './_ficheProjet.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { auth, firestore } from '../../firebase/config';
import { onAuthStateChanged } from 'firebase/auth';
import { getFirstLetters } from '../../useful/UsefulFunctions';
import NotificationsContainer from './Notifications/NotificationsContainer';
import { Pages } from './utils';
import { useProjectContext } from '../../contexts/projectContext';
import { PROJECT_COLLECTION, USER_COLLECTION } from '../../firebase/paths';
import ParticipantImg from '../../components/ParticipantImg';
import Contributors from './Contributors/Contributors';
import AgencyDataContext from '../../contexts/agencyDataContext';
import { useStripeContext } from '../../contexts/stripeContext';
import WrapperContext from './WrapperContext';
import Tickets from './Tickets/Tickets';
import { collection, query, where, or, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { Paths } from '../../firebase/paths';
import { TICKET_STATUS } from './Tickets/components/Modal Ticket/Follow Up/FollowUp';

export default function FicheProjet() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();

	const agencyId = useContext(AgencyContext);
	const type = useContext(TypeContext);
	const agencyData = useContext(AgencyDataContext);
	const stripe = useStripeContext();

	const [uid, setUid] = useState();
	const [userSession, setUserSession] = useState(null);

	const [loading, setLoading] = useState(true);

	const [displayNotifications, setDisplayNotifications] = useState(false);

	// Projet

	const [project, setProject] = useProjectContext();
	const [projectId, setProjectId] = useState();
	const [accounts, setAccounts] = useState([]);
	const [isModeLecture, setIsModeLecture] = useState(false);

	// Inter Agence

	const [partnerAgencies, setPartnerAgencies] = useState(new Map());

	// Pages

	const [page, setPage] = useState(Pages.FICHIERS);
	const [pagePermissions, setPagePermissions] = useState({});
	const [settingsSubPage, setSettingsSubPage] = useState(null);

	// Abonnement

	const isFreeTrial = type === 'collaborators' && !agencyData?.vip && stripe?.isFreeTrial;

	// Tickets

	const [issuePastille, setIssuePastille] = useState(0);
	const [missionPastille, setMissionPastille] = useState(0);

	// ========================================= Use Effects =========================================

	useEffect(() => {
		firestore
			.collection(PROJECT_COLLECTION)
			.doc(projectId)
			.collection(USER_COLLECTION)
			.where('type', '!=', 'agencies')
			.limit(5)
			.get()
			.then(async (querySnapshot) => {
				if (querySnapshot) {
					const accounts = [];
					querySnapshot.forEach((doc) => {
						if (doc && doc.exists) {
							const data = { ...doc.data(), id: doc.id };
							accounts.push(data);
						}
					});
					setAccounts(accounts);
				}
			});
	}, [projectId]);

	useEffect(() => {
		let listener = onAuthStateChanged(auth, (user) => {
			if (user) {
				setUserSession(user);
				setUid(user.uid);
			} else navigate('/');
		});

		return () => {
			listener();
		};
	}, [userSession]);

	useEffect(() => {
		if (location.state) {
			setProjectId(location.state.projectId);
			setProject((prev) =>
				prev.id === localStorage.getItem('projectId') ? prev : { id: localStorage.getItem('projectId') }
			);
			if (location.state.page) {
				setPage(location.state.page);
				localStorage.setItem('page', location.state.page);
			} else {
				setPage(localStorage.getItem('page') ? parseInt(localStorage.getItem('page')) : Pages.FICHIERS);
			}
		} else if (localStorage.getItem('projectId')) {
			setProjectId(localStorage.getItem('projectId'));
			setProject((prev) =>
				prev.id === localStorage.getItem('projectId') ? prev : { id: localStorage.getItem('projectId') }
			);
			setPage(localStorage.getItem('page') ? parseInt(localStorage.getItem('page')) : Pages.FICHIERS);
		} else {
			navigate('/projets');
		}
	}, [location]);

	useEffect(() => {
		if (projectId) {
			const subscriber1 = firestore.doc(`${PROJECT_COLLECTION}/${projectId}`).onSnapshot((documentSnapshot) => {
				if (documentSnapshot && documentSnapshot.exists) {
					setProject({ ...documentSnapshot.data(), id: documentSnapshot.id });
					setLoading(false);
				}
			});
			return () => subscriber1();
		}
	}, [projectId]);

	useEffect(() => {
		if (projectId && uid && (type === 'clients' || agencyId)) {
			const subscriber = firestore.collection(`projects/${projectId}/accounts`).onSnapshot((querySnapshot) => {
				if (querySnapshot) {
					const agencies = new Map();
					const promises = [];
					querySnapshot.forEach((doc) => {
						if (doc && doc.exists && doc.id !== agencyId && doc.data().type === 'agencies') {
							const promise = firestore
								.doc(`agencies/${doc.id}`)
								.get()
								.then((doc1) => {
									if (doc1 && doc1.exists) {
										agencies.set(doc1.id, {
											...doc1.data(),
											id: doc1.id,
											diminutifAgency:
												doc1.data().diminutifAgency ?? getFirstLetters(doc1.data().name),
										});
									}
								});
							promises.push(promise);
						}
					});
					Promise.all(promises).then(() => {
						setPartnerAgencies(agencies);
					});

					setIsModeLecture(!querySnapshot.docs.some((doc) => doc.id === uid));
				}
			});
			return () => subscriber();
		}
	}, [uid, projectId, agencyId, type]);

	useEffect(() => {
		if (projectId && agencyId && uid) {
			const q = query(
				collection(db, Paths.PROJECTS, projectId, Paths.TICKETS),
				or(
					where('properties.companies', 'array-contains', agencyId),
					where('properties.reviewers', 'array-contains', agencyId),
					where('properties.users', 'array-contains', uid),
					where('properties.owner.userId', '==', uid)
				)
			);
			const unsubscribe = onSnapshot(q, (querySnapshot) => {
				if (querySnapshot) {
					setIssuePastille(
						querySnapshot.docs.filter(
							(doc) =>
								doc.data().properties?.type === 'issue' &&
								(!doc.data().companiesViewed?.includes(agencyId) ||
									(doc.data().properties?.companies?.includes(agencyId) &&
										(doc.data().issueStatus === TICKET_STATUS.refused ||
											doc.data().issueStatus === TICKET_STATUS.inProgress)) ||
									(doc.data().properties?.reviewers?.includes(agencyId) &&
										doc.data().issueStatus === TICKET_STATUS.pendingApproval &&
										!doc.data().companiesReviewed?.includes(agencyId)))
						).length
					);
					setMissionPastille(
						querySnapshot.docs.filter(
							(doc) =>
								doc.data().properties?.type === 'mission' &&
								!doc.data().companiesViewed?.includes(uid) &&
								((doc.data().properties?.accessibility !== 'private' &&
									doc.data().properties?.users?.includes(uid)) ||
									doc.data().owner?.userId === uid)
						).length
					);
				}
			});
			return () => unsubscribe();
		}
	}, [projectId, agencyId, uid]);

	useEffect(() => {
		if (page !== Pages.PARAMETRES_PROJET) {
			setSettingsSubPage(null);
		}
	}, [page]);

	// ================================ Navigation ================================

	const [marginTop, setmarginTop] = useState(0);
	const [opacity, setOpacity] = useState(1);

	function goToPage(newPage) {
		setmarginTop(page < newPage || newPage === Pages.PARAMETRES_PROJET ? -30 : 30);
		setOpacity(0);
		setTimeout(() => {
			setPage(newPage);
			localStorage.setItem('page', newPage);
			setmarginTop(page < newPage || newPage === Pages.PARAMETRES_PROJET ? 30 : -30);
			setTimeout(() => {
				setmarginTop(0);
				setOpacity(1);
			}, 300);
		}, 300);
	}

	// Project Menu

	const workspacePages = [
		{
			page: Pages.SNAGGING,
			label: t('translation.snagging'),
			condition: !project?.withoutSnags,
		},
		{
			page: Pages.MISSIONS,
			label: t('translation.missions'),
			condition: !project?.withoutMissions,
		},
	];

	const getMarginTopFirstMenu = (page) => {
		switch (page) {
			case Pages.SNAGGING:
				return 0;
			case Pages.MISSIONS:
				return !project?.withoutSnags ? 35 : 0;
			default:
				return 200;
		}
	};

	const getMarginTop = (page) => {
		switch (page) {
			case Pages.SNAGGING:
				return -50;
			case Pages.MISSIONS:
				return -50;
			case Pages.FICHIERS:
				return 0;
			case Pages.PHOTOS:
				return 35;
			case Pages.PROGRESSION:
				return 71;
			case Pages.FINANCES:
				return 106;
			default:
				return 142;
		}
	};

	const shouldDisplayHeight = (page) => ![Pages.PARAMETRES_PROJET].includes(page);

	const getClassname = (currentPage, page, access) => (currentPage === page ? 'active' : 'unactive');

	const handleOnClick = (access, goTo) => {
		goToPage(goTo);
		setDisplayNotifications(false);
	};

	// ================================ Render ================================

	return (
		<WrapperContext pagePermissions={pagePermissions} setPagePermissions={setPagePermissions}>
			<div className={'ficheProjet'}>
				<div style={{ zIndex: 20 }}>
					<NotificationsContainer
						isModeLecture={isModeLecture}
						displayNotifications={displayNotifications}
						setDisplayNotifications={setDisplayNotifications}
					/>
				</div>

				<div style={{ display: 'flex', marginTop: 55, zIndex: 0 }}>
					<div style={{ display: 'flex' }}>
						{loading ? (
							<div className="menuFicheProjet">
								<div className={'chargementContainer'}>
									<img src={chargement} alt="" className={'chargement'} />
								</div>
							</div>
						) : (
							<div className="menuFicheProjet">
								<div>
									{workspacePages.some((page) => page.condition) && (
										<div>
											<p className={'menuName'}>Workspace</p>
											<div className="containerMenu">
												<div className="inner">
													<div className={'containerPastilles'}>
														{!project?.withoutSnags &&
															(issuePastille > 0 ? (
																<div style={{ display: 'flex' }}>
																	<div className={'pastilleWorkspace'}>
																		{issuePastille}
																	</div>
																</div>
															) : (
																<div style={{ display: 'flex' }}>
																	<div
																		className={'pastilleWorkspace'}
																		style={{ opacity: 0 }}
																	/>
																</div>
															))}
														{!project?.withoutMissions &&
															(missionPastille > 0 ? (
																<div style={{ display: 'flex' }}>
																	<div className={'pastilleWorkspace'}>
																		{missionPastille}
																	</div>
																</div>
															) : (
																<div style={{ display: 'flex' }}>
																	<div
																		className={'pastilleWorkspace'}
																		style={{ opacity: 0 }}
																	/>
																</div>
															))}
													</div>
													<div
														className="backBarMenu"
														style={{
															height:
																workspacePages.filter((page) => page.condition).length >
																1
																	? 56
																	: 20,
														}}>
														<div
															className="transitionWidthMarginLeft frontBarMenu"
															style={{
																marginTop: getMarginTopFirstMenu(page),
																height: shouldDisplayHeight(page) ? 20 : 0,
															}}
														/>
													</div>
													<div>
														{workspacePages.map(
															({ page: pageItem, access, label, condition }) =>
																condition !== false && (
																	<div
																		key={pageItem}
																		className={getClassname(page, pageItem)}
																		onClick={() => handleOnClick(access, pageItem)}>
																		<p>{t(label)}</p>
																	</div>
																)
														)}
													</div>
												</div>
											</div>
										</div>
									)}

									<div>
										<p className={'menuName'}>{t('translation.informations')}</p>
										<div className="containerMenu">
											<div>
												<div className="inner">
													<div
														className="backBarMenu"
														style={{ height: project?.withoutFinance ? 92 : 127 }}>
														<div
															className="transitionWidthMarginLeft frontBarMenu"
															style={{
																marginTop: getMarginTop(page),
																height: shouldDisplayHeight(page) ? 20 : 0,
															}}
														/>
													</div>
													<div>
														{[
															{
																page: Pages.FICHIERS,
																label: 'fiche_project.files',
															},
															{
																page: Pages.PHOTOS,
																label: 'photos.photos',
															},
															{
																page: Pages.PROGRESSION,
																label: 'translation.progress',
															},
															{
																page: Pages.FINANCES,
																label: 'fiche_project.finances',
																condition: !project?.withoutFinance,
															},
														].map(
															({ page: pageItem, access, label, condition }) =>
																condition !== false && (
																	<div
																		key={pageItem}
																		className={getClassname(page, pageItem)}
																		onClick={() => handleOnClick(access, pageItem)}>
																		<p>{t(label)}</p>
																	</div>
																)
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								{type !== 'clients' && (
									<div className={'widgetContributors'}>
										<div className={'containerProfiles'}>
											{accounts.map((item, index) => (
												<ParticipantImg uid={item.uid} type={item.type} key={index} />
											))}
										</div>

										<div className={'buttonInvite'} onClick={() => goToPage(Pages.CONTRIBUTORS)}>
											{agencyId === project?.creator && !isModeLecture
												? t('translation.inviteContributor')
												: t('translation.annuaire')}
										</div>

										{isModeLecture
											? null
											: agencyId === project?.creator && (
													<div
														className={'buttonSettings'}
														onClick={() => goToPage(Pages.PARAMETRES_PROJET)}>
														{t('common.settings')}
														<img src={settingsIcon} alt="" className={'settingsIcon'} />
													</div>
												)}
									</div>
								)}
							</div>
						)}
					</div>

					<div
						className={'transitionMenuProjet'}
						style={{
							opacity: opacity,
							marginTop: marginTop,
						}}>
						{project ? (
							page === Pages.PROGRESSION ? (
								<Progression isModeLecture={isModeLecture} />
							) : page === Pages.FINANCES ? (
								<Finances
									type={type}
									auth={uid}
									projectId={projectId}
									isMandataire={agencyId === project?.creator}
									isFreeTrial={isFreeTrial}
									isModeLecture={isModeLecture}
								/>
							) : page === Pages.PHOTOS ? (
								<PhotosPage
									type={type}
									projectId={projectId}
									projectImgUrl={project?.imgUrl ?? ''}
									partnerAgencies={partnerAgencies}
									isFreeTrial={isFreeTrial}
									isModeLecture={isModeLecture}
									handleModifyLots={() => {
										setSettingsSubPage('lots');
										goToPage(Pages.PARAMETRES_PROJET);
									}}
									handleModifyLocations={() => {
										setSettingsSubPage('localisations');
										goToPage(Pages.PARAMETRES_PROJET);
									}}
								/>
							) : page === Pages.FICHIERS ? (
								<Documents
									projectId={projectId}
									isMandataire={agencyId === project?.creator}
									isModeLecture={isModeLecture}
								/>
							) : page === Pages.CONTRIBUTORS ? (
								<Contributors
									auth={uid}
									projectId={projectId}
									projectData={project}
									setProjectData={setProject}
									isMandataire={agencyId === project?.creator}
									isModeLecture={isModeLecture}
								/>
							) : page === Pages.PARAMETRES_PROJET ? (
								<SettingsProjet defaultSubPage={settingsSubPage} />
							) : page === Pages.SNAGGING ? (
								<Tickets
									isSnagging={true}
									isReadOnly={isModeLecture}
									handleModifyLots={() => {
										setSettingsSubPage('lots');
										goToPage(Pages.PARAMETRES_PROJET);
									}}
									handleModifyLocations={() => {
										setSettingsSubPage('localisations');
										goToPage(Pages.PARAMETRES_PROJET);
									}}
								/>
							) : page === Pages.MISSIONS ? (
								<Tickets
									isSnagging={false}
									isReadOnly={isModeLecture}
									handleModifyLots={() => {
										setSettingsSubPage('lots');
										goToPage(Pages.PARAMETRES_PROJET);
									}}
									handleModifyLocations={() => {
										setSettingsSubPage('localisations');
										goToPage(Pages.PARAMETRES_PROJET);
									}}
								/>
							) : null
						) : (
							<div
								className={'chargementContainer'}
								style={{
									width: 'calc(100vw - 140px)',
									height: 'calc(100vh - 60px)',
								}}></div>
						)}
					</div>
				</div>

				<div style={{ display: 'flex', position: 'absolute', top: 0 }}>
					<MenuProjet
						isFreeTrial={isFreeTrial}
						grasProjet={true}
						ficheProjet={true}
						img={project?.imgUrl ?? ''}
						name={project?.name ?? ''}
						clientName={project?.clientName ?? ''}
						isModeLecture={isModeLecture}
						setDisplayNotifications={setDisplayNotifications}
					/>
				</div>
			</div>
		</WrapperContext>
	);
}
