import React, { useState } from 'react';
import './_locationOnSitePhoto.scss';
import upDownGrey from '../../../../../../../assets/upDownGrey.svg';
import mappin from '../../../../../../../assets/mappin.svg';
import { useTranslation } from 'react-i18next';
import { joinPaths } from '../../../../../../../firebase/utils';
import { useProjectLocations } from 'src/contexts/projectLocationsContext';
import { firestore } from 'src/firebase/config';
import { Paths } from 'src/firebase/paths';
import { useProjectContext } from 'src/contexts/projectContext';
import LocationsDropDown from 'src/pages/Fiche Projet/components/Locations Drop Down/LocationsDropDown';
import PropTypes from 'prop-types';

LocationOnSitePhoto.propTypes = {
	selectedPhoto: PropTypes.object.isRequired,
	setSelectedPhoto: PropTypes.func.isRequired,
	setPhotos: PropTypes.func.isRequired,
	handleModifyLocations: PropTypes.func.isRequired,
};

export default function LocationOnSitePhoto({ selectedPhoto, setSelectedPhoto, setPhotos, handleModifyLocations }) {
	const { t } = useTranslation();

	const [project] = useProjectContext();
	const [locations] = useProjectLocations();

	const [isListOpened, setIsListOpened] = useState(false);

	const handleChange = (value) => {
		firestore.doc(joinPaths(Paths.PROJECTS, project.id, Paths.MEDIAS, selectedPhoto.id)).update({
			locations: value,
		});
		setPhotos((prev) => prev.map((item) => (item.id === selectedPhoto.id ? { ...item, locations: value } : item)));
		setSelectedPhoto((prev) => ({
			...prev,
			locations: value,
		}));
	};

	return (
		<div
			className={'containerSelectLocation'}
			style={{
				border: 'solid 2px #eeeeee',
			}}>
			<div className={'headerSelect hover'} onClick={() => setIsListOpened(!isListOpened)}>
				<div style={{ display: 'flex' }}>
					<img
						src={mappin}
						alt=""
						className={'mappin'}
						style={{ opacity: selectedPhoto?.locations?.[0] === '' ? 0.4 : 1 }}
					/>
					<p className={'titleHeader'} style={{ opacity: selectedPhoto?.locations?.[0] === '' ? 0.4 : 1 }}>
						{selectedPhoto?.locations?.[0]
							? locations?.find((item) => item.id === selectedPhoto?.locations?.[0])?.name
							: t('translation.locationOnSite')}
					</p>
				</div>
				{<img src={upDownGrey} alt="" className={'upDown'} />}
			</div>

			{isListOpened && (
				<div className={'containerInputLocationOnSite'}>
					<LocationsDropDown
						selectedLocations={selectedPhoto?.locations}
						handleChange={handleChange}
						handleModifyLocations={handleModifyLocations}
						onClose={() => setIsListOpened(false)}
					/>
				</div>
			)}
		</div>
	);
}
