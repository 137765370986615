import React from 'react';
import './_subPageSettingsProject.scss';
import InputSettings from './components/InputSettings';
import { useProjectContext } from 'src/contexts/projectContext';
import { firestore, storage } from 'src/firebase/config';
import { PROJECT_COLLECTION } from 'src/firebase/paths';
import { resizeImage } from 'src/useful/image';
import { deleteStorageFromUrl, generateUniqueFirestoreId } from 'src/firebase/utils';
import { useTranslation } from 'react-i18next';

export default function General() {
	const { t } = useTranslation();

	const [project, setProject] = useProjectContext();

	const updateProject = async (key, value) => {
		setProject((prevState) => ({ ...prevState, [key]: value }));
		await firestore
			.collection(PROJECT_COLLECTION)
			.doc(project.id)
			.update({ [key]: value });
	};

	const imageHandler = async (e) => {
		try {
			const oldImgUrl = project?.imgUrl;

			const reader = new FileReader();
			reader.onload = async () => {
				setProject((prevState) => ({ ...prevState, imgUrl: reader.result }));
			};
			reader.readAsDataURL(e.target.files[0]);

			const resizedImage = await resizeImage(e.target.files[0]);
			const uploadTask = await storage
				.ref(`projects/${project.id}/pictures/${generateUniqueFirestoreId()}`)
				.put(resizedImage);
			const url = await uploadTask.ref.getDownloadURL();

			await deleteStorageFromUrl(oldImgUrl);
			await updateProject('imgUrl', url);
		} catch (error) {
			setProject((prevState) => ({ ...prevState, imgUrl: project.imgUrl }));
			throw new Error(`${t('translation.failedToUpdateImage')} ${error.message}`);
		}
	};

	return (
		<div className={'subPageSettingsProject'} style={{ paddingTop: 6 }}>
			<InputSettings
				type={'text'}
				name={t('translation.projectName')}
				defaultValue={project?.name}
				onValidate={async (e) => {
					const newName = e.target.value?.trim();
					if (newName && newName !== project?.name && newName.length > 0) {
						await updateProject('name', newName);
					} else {
						e.target.value = project?.name;
					}
				}}
			/>
			<InputSettings
				type={'text'}
				name={t('translation.reference')}
				defaultValue={project?.tag}
				onValidate={async (e) => {
					const newTag = e.target.value?.trim();
					if (newTag && newTag !== project?.tag && newTag.length > 0) {
						await updateProject('tag', newTag);
					} else {
						e.target.value = project?.tag;
					}
				}}
			/>
			<InputSettings name={t('translation.projectId')} type={'text'} disabled={true} defaultValue={project?.id} />
			<InputSettings
				name={t('translation.coverPicture')}
				type={'image'}
				defaultValue={project?.imgUrl}
				onValidate={imageHandler}
			/>
			<InputSettings
				name={t('translation.status')}
				type={'switch'}
				defaultValue={project?.active === false ? 'completed' : 'active'}
				options={[
					{ value: 'active', label: t('projects_page.ongoing') },
					{ value: 'completed', label: t('projects_page.completed') },
				]}
				onValidate={async (e) => {
					await updateProject('active', e.target.value === 'active');
				}}
			/>
		</div>
	);
}
