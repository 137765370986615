import React from 'react';
import selectAllIcon from '../../../../../assets/selectAllIcon.svg';
import logoExport from '../../../../../assets/exportIcon.png';
import logoImport from '../../../../../assets/importIcon.svg';
import { useTranslation } from 'react-i18next';
import printer from '../../../../../assets/printer.svg';
import { useTicketProperties } from '../../../Tickets/data';
import plusSquare from '../../../../../assets/plusSquare.svg';

export default function ButtonsPhotos({
	setIsSelectOption,
	isSelectOption,
	setSelectedPhotos,
	selectedPhotos,
	deletePhoto,
	downloadPhotos,
	noPictures,
	exportAlbum,
	setModalPrintReport,
	display,
	setDisplay,
	isVisit,
	setModalNewView,
	adminView,
	isMatterport,
}) {
	const { t } = useTranslation();
	const { properties } = useTicketProperties();

	return isSelectOption ? (
		<div className={'containerButtonPhotos'}>
			<div
				onClick={() => {
					setIsSelectOption((oldValue) => !oldValue);
					setSelectedPhotos([]);
				}}
				style={{ backgroundColor: '#daf8d2' }}
				className={'buttonPhoto hover'}>
				<img className={'iconButtonPhoto'} src={selectAllIcon} alt="iconPlus" />
				<p className={'textButtonPhoto'} style={{ color: '#328536' }}>
					{t('common.finish')}
				</p>
			</div>

			<div
				className={'buttonPhoto hover'}
				style={{ backgroundColor: 'rgba(185,23,23,0.15)' }}
				onClick={async () => {
					if (selectedPhotos.length > 0 && window.confirm(t('photos.confirm_delete_selection'))) {
						for (const photo of selectedPhotos) {
							await deletePhoto(photo);
						}
						setSelectedPhotos([]);
					}
				}}>
				<p className={'textButtonPhoto'} style={{ color: '#b91717' }}>
					{t('photos.delete_selection')}
				</p>
			</div>

			<div
				className={'buttonPhoto hover'}
				style={{ backgroundColor: 'rgba(73,103,236,0.15)' }}
				onClick={() => downloadPhotos(selectedPhotos)}>
				<p className={'textButtonPhoto'} style={{ color: '#4967EC' }}>
					{t('photos.download_selection')}
				</p>
			</div>
		</div>
	) : (
		<div className={'containerButtonPhotos'}>
			{/*isVisit && (
				<>
					<img
						src={display === 'grid' ? squaresPhotoBlue : squaresPhotos}
						alt=""
						className={display === 'grid' ? 'iconDisplayActive' : 'iconDisplay'}
						onClick={() => setDisplay('grid')}
					/>
					<img
						src={display === 'square' ? squareBlue : squareBlack}
						alt=""
						className={display === 'square' ? 'iconDisplayActive' : 'iconDisplay'}
						onClick={() => setDisplay('square')}
					/>
				</>
			)*/}

			<div />
			{isVisit && (
				<div onClick={() => setModalPrintReport(true)} className={'buttonPhoto hover'}>
					<img className={'iconButtonPhoto'} src={printer} alt="iconPlus" />
					<p className={'textButtonPhoto'}>{t('translation.printReport')}</p>
				</div>
			)}

			{!noPictures && (
				<div
					onClick={() => {
						setIsSelectOption((oldValue) => !oldValue);
						setSelectedPhotos([]);
					}}
					style={{ backgroundColor: isSelectOption ? '#daf8d2' : '#eeeeee' }}
					className={'buttonPhoto hover'}>
					<img className={'iconButtonPhoto'} src={selectAllIcon} alt="iconPlus" />
					<p className={'textButtonPhoto'} style={{ color: isSelectOption ? '#328536' : '#000' }}>
						{isSelectOption ? t('common.finish') : t('common.select')}
					</p>
				</div>
			)}

			{!isMatterport && (
				<div className={'buttonPhoto hover'} onClick={exportAlbum}>
					<img className={'iconButtonPhoto'} src={logoExport} alt="" />
					<p className={'textButtonPhoto'}>{t('common.export')}</p>
				</div>
			)}

			{!isMatterport && (
				<div style={{ display: 'flex' }}>
					<label htmlFor={'input'}>
						<div className={'buttonPhoto hover'}>
							<img className={'iconButtonPhoto'} src={logoImport} alt="" />
							<p className={'textButtonPhoto'}>{t('common.import')}</p>
						</div>
					</label>
				</div>
			)}

			{isMatterport && (
				<div
					style={{ backgroundColor: adminView ? '#daf8d2' : '#eeeeee' }}
					className={'buttonPhoto hover'}
					onClick={() => setModalNewView(true)}>
					<img className={'iconButtonPhoto'} src={plusSquare} alt="iconPlus" />
					<p className={'textButtonPhoto'} style={{ color: adminView ? '#328536' : '#000' }}>
						{t('photos.add_visit')}
					</p>
				</div>
			)}
		</div>
	);
}
