import { useMemo, useState, useEffect, useContext } from 'react';
import AgencyDataContext from 'src/contexts/agencyDataContext';
import AuthDataContext from 'src/contexts/authDataContext';
import { useClientsContext } from 'src/contexts/clientsContext';

const useDataInformations = (object) => {
	const currentUser = useContext(AuthDataContext);
	const agency = useContext(AgencyDataContext);
	const [clients] = useClientsContext();

	const isClient = useMemo(() => {
		return currentUser.type === 'clients' || clients.find((client) => client.id === agency.id);
	}, [currentUser, clients, agency]);

	const [canModify, setCanModify] = useState(false);

	useEffect(() => {
		if (object.createdBy) {
			if (currentUser.id === object.createdBy.userId || agency.id === object.createdBy.agencyId) {
				setCanModify(true);
				return;
			}

			if (isClient) {
				setCanModify(
					object.createdBy.userType === 'client' ||
						clients.find((client) => client.id === object.createdBy.agencyId)
				);
				return;
			}

			setCanModify(false);
		} else {
			if (currentUser.id === object.uid || agency.id === object.agency) {
				setCanModify(true);
				return;
			}

			if (isClient) {
				setCanModify(object.agency === 'clients' || clients.find((client) => client.id === object.agency));
				return;
			}

			setCanModify(false);
		}
	}, [agency, clients, currentUser, isClient, object]);

	return { canModify };
};

export default useDataInformations;
