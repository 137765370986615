import React, { useEffect, useRef, useState } from 'react';
import './_locationItemVisit.scss';
import { useTranslation } from 'react-i18next';
import mappin from 'src/assets/mappin.svg';
import plusIcon from 'src/assets/plusIcon.svg';
import PhotoItem from '../../components/PhotoItem';
import { firestore } from 'src/firebase/config';
import { joinPaths } from 'src/firebase/utils';
import { Paths } from 'src/firebase/paths';
import { useProjectContext } from 'src/contexts/projectContext';
import useDataInformations from 'src/pages/Fiche Projet/useDataInformations';

export default function LocationItemVisit({
	album,
	pictures,
	index,
	agencyData,
	isSelectOption,
	setSelectedPhoto,
	opacityPhoto,
	partnerAgencies,
	selectedPhotos,
	setDropZoneIsActive,
	setModalPhoto,
	setSelectedPhotos,
	handleDownload,
	locationName,
	locationId,
}) {
	const { t, i18n } = useTranslation();

	const [project] = useProjectContext();
	const { canModify } = useDataInformations(album);

	const textareaRef = useRef(null);

	const [locationDescription, setLocationDescription] = useState(album?.levelDescriptions?.[locationId] || '');
	const [textAreaIsFocus, setTextAreaIsFocus] = useState(false);

	const adjustHeight = () => {
		const textarea = textareaRef.current;
		if (textarea) {
			textarea.style.height = 'auto';
			textarea.style.height = textarea.scrollHeight + 'px';
		}
	};

	useEffect(() => {
		adjustHeight();
	}, []);

	const onBlur = () => {
		setTextAreaIsFocus(false);
		if (locationDescription !== album?.levelDescriptions?.[locationId]) {
			firestore.doc(joinPaths(Paths.PROJECTS, project.id, Paths.MEDIAS, album.id)).update({
				['levelDescriptions.' + locationId]: locationDescription,
			});
		}
	};

	const onChangeFiles = (e) => {
		if (e.target.files.length > 0) {
			handleDownload(e.target.files, locationId !== 'no_location' ? [locationId] : []);
		}
	};

	return (
		<div className={'locationItemVisit'} key={index}>
			<div className={'stickyPart'}>
				<span className={'titleLocation'}>
					<img src={mappin} alt="" className={'mappin'} />
					{locationName}
				</span>
				<span className={'numberPictures'}>
					{pictures.length} {t('common.photos')}
				</span>
				{locationId !== 'no_location' && (locationDescription?.length > 0 || canModify) && (
					<textarea
						disabled={!canModify}
						onFocus={() => setTextAreaIsFocus(true)}
						onBlur={onBlur}
						ref={textareaRef}
						style={{ opacity: textAreaIsFocus || locationDescription.length === 0 ? 1 : 0.5 }}
						className={'locationComment'}
						placeholder={t('Write your level description here...')}
						value={locationDescription}
						onChange={(e) => {
							setLocationDescription(e.target.value);
							adjustHeight();
						}}
					/>
				)}
				<div style={{ display: 'flex', justifyContent: 'flex-start' }}>
					<label className={'addPicturesButton hover'} htmlFor={`addPictures${locationId}`}>
						<img src={plusIcon} alt="" className={'plusIcon'} /> {t('Add pictures')}
					</label>
					<input
						id={`addPictures${locationId}`}
						type="file"
						multiple
						accept="image/*,video/*,.heic,.heif"
						onChange={onChangeFiles}
						style={{ display: 'none' }}
					/>
				</div>
			</div>

			<div className={'photosPart'}>
				{pictures.map((value, index) => (
					<PhotoItem
						key={`item-${index}`}
						item={value}
						selectedPhotos={selectedPhotos}
						setSelectedPhotos={setSelectedPhotos}
						setSelectedPhoto={setSelectedPhoto}
						isSelectOption={isSelectOption}
						setModalPhoto={setModalPhoto}
						setDropZoneIsActive={setDropZoneIsActive}
						opacityPhoto={opacityPhoto}
						agencyData={agencyData}
						partnerAgencies={partnerAgencies}
						isVisit={true}
					/>
				))}
			</div>
		</div>
	);
}
