import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal/lib/components/Modal';
import crossIcon from 'src/assets/crossIcon.png';
import { auth, firestore, storage } from 'src/firebase/config';
import AgencyContext from 'src/contexts/agencyContext';
import TypeContext from 'src/contexts/typeContext';
import { deleteStorageFromUrl, generateUniqueFirestoreId, joinPaths } from 'src/firebase/utils';
import Resizer from 'react-image-file-resizer';
import { MEDIA_COLLECTION, Paths, PROJECT_COLLECTION } from 'src/firebase/paths';
import { useTranslation } from 'react-i18next';

export default function ModalNewView({
	modalNewView,
	setModalNewView,
	albumData,
	projectId,
	setPhotos,
	isModif,
	viewData,
	setModalView,
}) {
	const uid = auth.currentUser?.uid;
	const type = useContext(TypeContext);
	const agencyId = useContext(AgencyContext);
	const { t } = useTranslation();

	const [name, setName] = useState('');
	const [date, setDate] = useState('');
	const [shareLink, setShareLink] = useState('');
	const [password, setPassword] = useState('');
	const [imgCoverPicture, setImgCoverPicture] = useState();
	const [imgFile, setImgFile] = useState();

	useEffect(() => {
		if (isModif && viewData) {
			setName(viewData.name);
			setDate(new Date(viewData.orderDate).toISOString().split('T')[0]);
			setShareLink(viewData.url);
			setPassword(viewData.password);
			setImgCoverPicture(viewData.thumbnailUrl);
		}
	}, [isModif, viewData, modalNewView]);

	const fileHandler = async (e) => {
		const reader = new FileReader();
		reader.onload = () => {
			if (reader.readyState === 2) {
				setImgCoverPicture(reader.result);
			}
		};
		reader.readAsDataURL(e.target.files[0]);
		setImgFile(e.target.files[0]);
	};

	async function handleSubmit() {
		if (name && date && imgCoverPicture && shareLink) {
			if (imgFile) {
				Resizer.imageFileResizer(
					imgFile,
					2000,
					2000,
					'jpeg',
					30,
					0,
					async (file) => {
						if (isModif) {
							await deleteStorageFromUrl(viewData.imgUrl);
						}
						const storageRef = storage.ref(`${PROJECT_COLLECTION}/${projectId}/${MEDIA_COLLECTION}`);
						const id = isModif ? viewData.id : generateUniqueFirestoreId();
						const fileRef = storageRef.child(id);
						await fileRef.put(file);
						const imgUrl = await fileRef.getDownloadURL();
						const isoDate = new Date(date).toISOString();
						const data = {
							albumId: albumData.id,
							createdAt: new Date().toISOString(),
							createdBy: {
								userId: uid,
								userType: type === 'clients' ? 'client' : 'collaborator',
								agencyId: type === 'clients' ? uid : agencyId,
							},
							deletedAt: null,
							fileType: file.type,
							fileSize: file.size,
							id,
							isFavorite: false,
							modifiedAt: new Date().toISOString(),
							name: name,
							orderDate: isoDate,
							password: password ?? '',
							projectId,
							type: 'matterport',
							url: shareLink,
							thumbnailUrl: imgUrl,
						};
						if (isModif) {
							setPhotos((prevState) => {
								const newState = [...prevState];
								const index = newState.findIndex((item) => item.id === viewData.id);
								newState[index] = data;
								return newState;
							});
						} else {
							setPhotos((prevState) => {
								const newState = [...prevState];
								newState.push(data);
								newState.sort((a, b) => -a.orderDate.localeCompare(b.orderDate));
								return newState;
							});
						}
						await firestore
							.collection(`${PROJECT_COLLECTION}/${projectId}/${MEDIA_COLLECTION}`)
							.doc(id)
							.set(data, { merge: true });
					},
					'file',
					400,
					400
				);
			} else {
				const id = viewData.id;
				const isoDate = new Date(date).toISOString();
				const data = {
					modifiedAt: new Date().toISOString(),
					name: name,
					orderDate: isoDate,
					password: password ?? '',
					url: shareLink,
				};
				setPhotos((prevState) => {
					const newState = [...prevState];
					const index = newState.findIndex((item) => item.id === viewData.id);
					newState[index] = data;
					return newState;
				});
				await firestore
					.collection(`${PROJECT_COLLECTION}/${projectId}/${MEDIA_COLLECTION}`)
					.doc(id)
					.update(data, { merge: true });
			}
			setName('');
			setDate('');
			setShareLink('');
			setPassword('');
			setImgCoverPicture(null);
			setImgFile(null);
			setModalNewView(false);
		} else {
			alert(t('translation.pleaseFillAllInputs'));
		}
	}

	async function handleDelete() {
		if (window.confirm(t('translation.sureToDeleteThisVisit'))) {
			await deleteStorageFromUrl(viewData.imgUrl);
			await firestore
				.collection(joinPaths(Paths.PROJECTS, projectId, Paths.MEDIAS))
				.doc(viewData.id)
				.delete();
			setPhotos((prevState) => {
				const newState = [...prevState];
				const index = newState.findIndex((item) => item.id === viewData.id);
				newState.splice(index, 1);
				return newState;
			});

			setName('');
			setDate('');
			setShareLink('');
			setPassword('');
			setImgCoverPicture(null);
			setImgFile(null);
			setModalNewView(false);
			setModalView(false);
		}
	}

	function handleCloseModal() {
		setName('');
		setDate('');
		setShareLink('');
		setPassword('');
		setImgCoverPicture();
		setModalNewView(false);
	}

	return (
		<Modal
			isOpen={modalNewView}
			className={'modalNewView'}
			overlayClassName={'overlayModalAlbumPhoto'}
			onRequestClose={handleCloseModal}
			shouldCloseOnOverlayClick={true}
			closeTimeoutMS={300}>
			<img src={crossIcon} alt="crossIcon" className={'crossIcon'} onClick={handleCloseModal} />

			<div>
				<p className={'title'}>{isModif ? t('translation.edits') : t('translation.newVisit')}</p>
				<>
					<div style={{ display: 'flex', marginTop: 25 }}>
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<p className={'nameInput'}>{t('translation.nameThreeDView')}</p>
							<input
								type="text"
								placeholder={t('translation.exampleMatterport')}
								className={'littleInput'}
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
						</div>
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<p className={'nameInput'}>{t('translation.matterportDate')}</p>
							<input
								type="date"
								placeholder={t('translation.exempleDate')}
								className={'littleInput'}
								value={date}
								onChange={(e) => setDate(e.target.value)}
							/>
						</div>
					</div>

					<div style={{ display: 'flex', marginTop: 20 }}>
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<p className={'nameInput'}>{t('translation.shareLink')}</p>
							<input
								type="text"
								placeholder={'Ex. https://my.matterport.com/show/?m=dDfNqbZZXM7'}
								className={'littleInput'}
								value={shareLink}
								onChange={(e) => setShareLink(e.target.value)}
							/>
						</div>
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<p className={'nameInput'}>{t('translation.optionalPassword')}</p>
							<input
								type="texte"
								placeholder={t('translation.exempleMatterport')}
								className={'littleInput'}
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
						</div>
					</div>

					<div>
						<p
							style={{
								marginTop: 40,
								fontWeight: 600,
								marginLeft: 20,
							}}>
							{t('translation.visitPreview')}{' '}
						</p>
						<div
							style={{
								backgroundColor: '#eeeeee',
								width: 600,
								height: 'calc(600px * 0.565)',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								borderRadius: 20,
							}}>
							{shareLink === '' ? (
								<p
									style={{
										margin: 0,
										fontWeight: 600,
										fontSize: 14,
									}}>
									{t('translation.fillWithTheLink')}{' '}
								</p>
							) : (
								<iframe
									width="100%"
									height="100%"
									src={shareLink}
									frameBorder="0"
									allowFullScreen
									allow="xr-spatial-tracking"
								/>
							)}
						</div>
					</div>

					<p
						style={{
							marginTop: 40,
							fontWeight: 600,
							marginLeft: 20,
							marginBottom: 20,
						}}>
						{t('translation.matterportCiverPicture')}{' '}
					</p>

					<div className="inputCoverPicture" style={{ zIndex: 1 }}>
						<label htmlFor="inputCoverPicture">
							{!imgCoverPicture ? (
								<div className="photos">
									<div className="img-holder">
										<div className="label">
											<p
												style={{
													margin: 0,
													fontWeight: 600,
													fontSize: 14,
												}}>
												{t('translation.clicHereToAddPhoto')}
											</p>
										</div>
									</div>
								</div>
							) : (
								<img src={imgCoverPicture} className="photos" alt="" id="img" />
							)}
						</label>
						<input
							type="file"
							id="inputCoverPicture"
							accept="image/*,.heic, .heif"
							onChange={fileHandler}
						/>
					</div>
				</>

				<div style={{ display: 'flex' }}>
					{isModif && (
						<div className={'buttonSupprimer'} style={{ width: 227 }} onClick={handleDelete}>
							{t('translation.delete')}
						</div>
					)}
					<div className={'buttonPublier'} style={{ width: isModif ? 227 : 540 }} onClick={handleSubmit}>
						{isModif ? t('translation.save') : t('translation.publishVisit')}
					</div>
				</div>
			</div>
		</Modal>
	);
}
