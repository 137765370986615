import React, { useContext, useEffect, useRef, useState } from 'react';
import Modal from 'react-modal/lib/components/Modal';
import crossIcon from '../../assets/crossIcon.png';
import { useTranslation } from 'react-i18next';
import Matterport from '../../assets/Matterport.png';
import ItemAccessibilityPhotos from 'src/pages/Fiche Projet/Photos/Album Content/Modification/ItemAccessibilityPhotos/ItemAccessibilityPhotos';
import { usePartnersContext } from 'src/contexts/partnersContext';
import './ModalTyping.css';
import AgencyContext from 'src/contexts/agencyContext';

export default function ModalTyping({
	modalTyping,
	setModalTyping,
	title,
	func,
	placeholder,
	defaultValue,
	matterportActive,
	isDate,
	chooseAutorisations,
}) {
	const { t } = useTranslation();

	const agencyId = useContext(AgencyContext);
	const [partners] = usePartnersContext();

	const [text, setText] = useState('');
	const [displayAuthorisations, setDisplayAuthorisations] = useState(false);
	const [selectedPermissions, setSelectedPermissions] = useState(['all']);

	const ref = useRef();

	useEffect(() => {
		if (modalTyping) {
			setTimeout(() => {
				ref.current?.focus();
			}, 500);
		}
	}, [modalTyping]);

	useEffect(() => {
		setText(defaultValue ? defaultValue : '');
	}, [modalTyping]);

	return (
		<Modal
			isOpen={modalTyping}
			className={matterportActive ? 'bigModalTyping' : 'modalTyping'}
			overlayClassName="overlayModalTyping"
			onRequestClose={() => {
				setModalTyping(false);
				setText('');
				setDisplayAuthorisations(false);
				setSelectedPermissions(['all']);
			}}
			shouldCloseOnOverlayClick={true}
			closeTimeoutMS={300}>
			<div
				className={'modalTyping__crossButton'}
				onClick={() => {
					setModalTyping(false);
					setText('');
					setDisplayAuthorisations(false);
					setSelectedPermissions(['all']);
				}}>
				<img src={crossIcon} alt="cross" />
			</div>
			<p className={'modalTyping__title'}>{title}</p>

			{matterportActive ? (
				<div>
					<input
						type="text"
						ref={ref}
						className={'modalTyping__input'}
						placeholder={t('common.last_name')}
						value={text}
						onChange={(e) => setText(e.target.value)}
					/>
					<div style={{ display: 'flex' }}>
						<button
							onClick={async (e) => {
								e.preventDefault();
								setModalTyping(false);
								await func(text, selectedPermissions);
								setText('');
								setSelectedPermissions(['all']);
							}}
							className={'modalTyping__button'}
							style={{ borderWidth: 0 }}>
							{t('photos.create_standard_album')}
						</button>
						<button
							className={'modalTyping__button'}
							style={{ borderWidth: 0, marginRight: 20 }}
							onClick={async (e) => {
								e.preventDefault();
								setModalTyping(false);
								await func(text, selectedPermissions, true);
								setText('');
								setSelectedPermissions(['all']);
							}}>
							{t('photos.create_matterport_album')}
							<img
								src={Matterport}
								alt=""
								style={{
									width: 22,
									objectFit: 'contain',
									display: 'flex',
									position: 'absolute',
									right: 0,
									marginRight: 27,
								}}
							/>
						</button>
					</div>
				</div>
			) : (
				<form
					onSubmit={async (e) => {
						e.preventDefault();
						setModalTyping(false);
						await func(text, selectedPermissions);
						setText('');
						setSelectedPermissions(['all']);
					}}>
					<div style={{ display: 'flex' }}>
						<input
							type={isDate ? 'date' : 'text'}
							ref={ref}
							className={'modalTyping__input'}
							placeholder={placeholder ?? t('common.last_name')}
							value={text}
							onChange={(e) => setText(e.target.value)}
						/>
					</div>

					<button className={'modalTyping__button'} style={{ borderWidth: 0 }} disabled={text.length === 0}>
						{t('common.save')}
					</button>
				</form>
			)}

			{chooseAutorisations && (
				<div
					className={'modalTyping__buttonChooseAutorisations'}
					onClick={() => setDisplayAuthorisations(true)}>
					{t('translation.everyOneWillSeeThisAlbum')}{' '}
					<span style={{ color: '#1C57DD', marginLeft: 5, fontWeight: '700' }} className={'hover'}>
						{' '}
						{t('translation.modify')}
					</span>
				</div>
			)}

			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className={'modalTyping__autorisationsList'} style={{ height: displayAuthorisations ? 400 : 0 }}>
					<div className={'modalTyping__title'}>{t('translation.autorisations')}</div>

					<div style={{ margin: '0 5px 0 5px' }}>
						<ItemAccessibilityPhotos
							name={t('translation.allUsers')}
							isActivated={selectedPermissions.includes('all')}
							handleSimpleChoice={() => {
								setSelectedPermissions((prevState) => {
									if (prevState.includes('all')) {
										return [agencyId];
									}
									return ['all'];
								});
							}}
						/>
					</div>

					<div className={'modalTyping__bar'} />

					<div
						style={{
							margin: '0 5px 15px 5px',
							opacity: selectedPermissions.includes('all') ? 0.4 : 1,
							transition: 'opacity 0.3s ease-in-out',
						}}>
						<ItemAccessibilityPhotos
							name={t('translation.mo')}
							isActivated={selectedPermissions.includes('clients') || selectedPermissions.includes('all')}
							handleSimpleChoice={() => {
								setSelectedPermissions((prevState) => {
									if (prevState.includes('all')) {
										return [
											agencyId,
											...partners.map((item) => item.id).filter((id) => id !== agencyId),
										];
									}
									if (prevState.includes('clients')) {
										return prevState.filter((id) => id !== 'clients');
									}
									return [...prevState.filter((id) => id !== 'all'), 'clients'];
								});
							}}
						/>
					</div>

					{partners.map((partner) => (
						<div
							style={{
								margin: '0 5px 15px 5px',
								opacity: selectedPermissions.includes('all') ? 0.4 : 1,
								transition: 'opacity 0.3s ease-in-out',
							}}
							key={partner.id}>
							<ItemAccessibilityPhotos
								name={partner.name}
								isActivated={
									selectedPermissions.includes(partner.id) || selectedPermissions.includes('all')
								}
								handleSimpleChoice={() => {
									setSelectedPermissions((prevState) => {
										if (prevState.includes('all')) {
											return [
												'clients',
												...partners.map((item) => item.id).filter((id) => id !== partner.id),
											];
										}
										if (prevState.includes(partner.id)) {
											return prevState.filter((id) => id !== partner.id);
										}
										return [...prevState.filter((id) => id !== 'all'), partner.id];
									});
								}}
							/>
						</div>
					))}

					<div style={{ height: 20 }} />
				</div>
			</div>
		</Modal>
	);
}
