import React, { useEffect, useState } from 'react';
import './_containerAboutPicture.scss';
import screenInfos from '../../../../../../assets/screenInfos.svg';
import AnnotationsPart from './Annotation Part/AnnotationsPart';
import LocationOnSitePhoto from './Location On Site Photo/LocationOnSitePhoto';

export default function ContainerAboutPicture({
	bigScreen,
	setBigScreen,
	selectedPhoto,
	setSelectedPhoto,
	setPhotos,
	handleModifyLocations,
}) {
	return (
		<div
			style={{
				width: bigScreen ? 0 : 400,
			}}
			className={'containerAboutPicture transition'}>
			<div className={'containerAnnotationIcon'}>
				<div
					className={'containerScreenInfoIcon transition hover'}
					style={{
						marginLeft: bigScreen ? -570 : -360,
					}}>
					<img
						onClick={() => {
							if (bigScreen) {
								setBigScreen(false);
							} else {
								setBigScreen(true);
							}
						}}
						src={screenInfos}
						className={'screenInfoIcon hover'}
						alt=""
					/>
				</div>
				<p>About</p>
			</div>

			<LocationOnSitePhoto
				selectedPhoto={selectedPhoto}
				setSelectedPhoto={setSelectedPhoto}
				setPhotos={setPhotos}
				handleModifyLocations={handleModifyLocations}
			/>

			<AnnotationsPart selectedPhoto={selectedPhoto} setSelectedPhoto={setSelectedPhoto} setPhotos={setPhotos} />
		</div>
	);
}
