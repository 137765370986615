import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal/lib/components/Modal';
import crossIcon from '../../../../../assets/crossIcon.png';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useProjectContext } from 'src/contexts/projectContext';
import { firestore } from 'src/firebase/config';
import AgencyDataContext from 'src/contexts/agencyDataContext';
import { usePartnersContext } from 'src/contexts/partnersContext';

ModalEditProjectInformations.propTypes = {
	modal: PropTypes.bool.isRequired,
	setModal: PropTypes.func.isRequired,
	isPrivate: PropTypes.bool,
};

export default function ModalEditProjectInformations({ modal, setModal, isPrivate = false }) {
	const { t } = useTranslation();

	const [project] = useProjectContext();
	const agencyData = useContext(AgencyDataContext);
	const [partners] = usePartnersContext();

	const defaultData = isPrivate
		? {
				referentCollaborator: project.referentCollaborator || '',
				missionFramework: project.missionFramework || '',
				risk: project.risk || '',
				estimatedFees: project.estimatedFees || '',
				billedFees: project.billedFees || '',
				contractSignatureDate: project.contractSignatureDate || '',
				clientContactDetails: project.clientContactDetails || '',
			}
		: {
				projectDescription: project.projectDescription || '',
				address: project.address || '',
				mandataireName:
					project?.creator === agencyData?.id
						? agencyData?.name
						: partners?.find((partner) => partner.id === project.mandataireId)?.name || '',
				clientName: project.clientName || '',
				assistClientName: project.assistClientName || '',
				workAmount: project.workAmount || '',
				receptionDate: project.receptionDate || '',
				worksDuration: project.worksDuration || '',
			};

	const [newData, setNewData] = useState(defaultData);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (modal) {
			setNewData(defaultData);
		}
	}, [modal]);

	const handleCancel = () => {
		setNewData(defaultData);
		setModal(false);
	};

	const handleSave = async () => {
		const trimmedData = Object.fromEntries(Object.entries(newData).map(([key, value]) => [key, value.trim()]));
		const isDataChanged = Object.entries(trimmedData).some(([key, value]) => value !== defaultData[key]);

		if (trimmedData.worksDuration !== defaultData.worksDuration) {
			trimmedData.workDurationModifiedAt = new Date().toISOString();
		}

		if (trimmedData.billedFees !== defaultData.billedFees) {
			trimmedData.billedFeesModifiedAt = new Date().toISOString();
		}

		if (isDataChanged) {
			setLoading(true);
			await firestore.collection('projects').doc(project.id).update(trimmedData);
			setTimeout(() => {
				setModal(false);
				setTimeout(() => {
					setLoading(false);
				}, 500);
			}, 500);
		} else {
			setModal(false);
		}
	};

	return (
		<Modal
			isOpen={modal}
			className={'modalEditProjectInformations'}
			overlayClassName="OverlayModal"
			onRequestClose={handleSave}
			shouldCloseOnOverlayClick={true}
			closeTimeoutMS={300}>
			<div className={'headerModalProjectInformations'}>
				<p className={'titleHeaderModalProjectInformations'}>
					{isPrivate ? t('translation.editPrivateInformations') : t('translation.editPublicInformations')}
				</p>
				<img src={crossIcon} alt="" className={'crossIcon hover'} onClick={handleSave} />
			</div>

			{loading ? null : isPrivate ? (
				<div className={'containerInputs'}>
					<InputModalEditProjectInformations
						name={t('translation.designatedCollab')}
						placeholder={'Quentin Gross'}
						value={newData.referentCollaborator}
						setValue={(value) => setNewData({ ...newData, referentCollaborator: value })}
					/>
					<InputModalEditProjectInformations
						name={t('translation.missionScope')}
						placeholder={t('translation.classicArchitecturalMission')}
						value={newData.missionFramework}
						setValue={(value) => setNewData({ ...newData, missionFramework: value })}
					/>
					<InputModalEditProjectInformations
						name={t('translation.riskLevel')}
						placeholder={t('translation.weak')}
						value={newData.risk}
						setValue={(value) => setNewData({ ...newData, risk: value })}
					/>

					<InputModalEditProjectInformations
						name={t('translation.estimatedFees')}
						placeholder={t('translation.sixFiveO')}
						value={newData.estimatedFees}
						setValue={(value) => setNewData({ ...newData, estimatedFees: value })}
					/>
					<InputModalEditProjectInformations
						name={t('translation.billedFees')}
						placeholder={t('translation.twoSixSeven')}
						value={newData.billedFees}
						setValue={(value) => setNewData({ ...newData, billedFees: value })}
					/>
					<InputModalEditProjectInformations
						type={'textArea'}
						name={t('translation.contractInformations')}
						placeholder={t('translation.juneSeventeen')}
						value={newData.contractSignatureDate}
						setValue={(value) => setNewData({ ...newData, contractSignatureDate: value })}
					/>
					<InputModalEditProjectInformations
						type={'textArea'}
						name={t('Client contact details')}
						placeholder={t('Address, phone number, email')}
						value={newData.clientContactDetails}
						setValue={(value) => setNewData({ ...newData, clientContactDetails: value })}
					/>

					<div className={'containerButtonsSave'}>
						<div className={'cancel hover'} onClick={handleCancel}>
							{t('translation.cancel')}
						</div>
						<div className={'save hover'} onClick={handleSave}>
							{t('translation.save')}
						</div>
					</div>
				</div>
			) : (
				<div className={'containerInputs'}>
					<InputModalEditProjectInformations
						name={t('translation.projectDescription')}
						type={'textArea'}
						placeholder={t('translation.description')}
						value={newData.projectDescription}
						setValue={(value) => setNewData({ ...newData, projectDescription: value })}
					/>

					<InputModalEditProjectInformations
						name={t('project_settings.project_address')}
						placeholder={t('project_settings.placeholder_address')}
						value={newData.address}
						setValue={(value) => setNewData({ ...newData, address: value })}
					/>

					<InputModalEditProjectInformations
						name={t('translation.opusMandatoryCompany')}
						subtitle={t('translation.subtitleOpusMandatoryCompany')}
						placeholder={'A&D Architectes'}
						disabled={true}
						value={newData.mandataireName}
					/>

					<InputModalEditProjectInformations
						name={t('translation.mo')}
						placeholder={'Louis Richard'}
						value={newData.clientName}
						setValue={(value) => setNewData({ ...newData, clientName: value })}
					/>

					<InputModalEditProjectInformations
						name={t('translation.assistMo')}
						placeholder={'Jules Andrieux'}
						value={newData.assistClientName}
						setValue={(value) => setNewData({ ...newData, assistClientName: value })}
					/>
					<InputModalEditProjectInformations
						name={t('translation.priceWithoutTax')}
						placeholder={t('translation.heightHundredThousand')}
						value={newData.workAmount}
						setValue={(value) => setNewData({ ...newData, workAmount: value })}
					/>

					<InputModalEditProjectInformations
						name={t('translation.receptionDate')}
						placeholder={t('translation.june')}
						value={newData.receptionDate}
						setValue={(value) => setNewData({ ...newData, receptionDate: value })}
					/>
					<InputModalEditProjectInformations
						name={t('translation.worksDuration')}
						placeholder={t('translation.thirtySixMonths')}
						value={newData.worksDuration}
						setValue={(value) => setNewData({ ...newData, worksDuration: value })}
					/>

					<div className={'containerButtonsSave'}>
						<div className={'cancel hover'} onClick={handleCancel}>
							{t('translation.cancel')}
						</div>
						<div className={'save hover'} onClick={handleSave}>
							{t('translation.save')}
						</div>
					</div>
				</div>
			)}

			{loading && (
				<div className="deletion-overlay">
					<div className="deletion-spinner"></div>
					<p>{t('translation.saveInProgress')}</p>
				</div>
			)}
		</Modal>
	);
}

InputModalEditProjectInformations.propTypes = {
	type: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	setValue: PropTypes.func.isRequired,
	subtitle: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
};

function InputModalEditProjectInformations({ type, name, value, setValue, subtitle, placeholder, disabled = false }) {
	return (
		<div className={'inputModalEditProjectInformations'}>
			<p className={'nameInputModalEditProjectInformations'}>{name}</p>
			{type === 'textArea' ? (
				<textarea
					className={'inputTextModalEditProjectInformations'}
					placeholder={placeholder}
					cols={30}
					rows={10}
					value={value}
					onChange={(e) => setValue(e.target.value)}
					disabled={disabled}
				/>
			) : (
				<input
					className={'inputTextModalEditProjectInformations'}
					type="text"
					value={value}
					placeholder={placeholder}
					onChange={(e) => setValue(e.target.value)}
					disabled={disabled}
				/>
			)}
			<p className={'subtitleInputModalEditProjectInformations'}>{subtitle}</p>
		</div>
	);
}
