import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import triangle from '../../../../assets/triangle.svg';
import triangleVert from '../../../../assets/triangleVert.svg';
import checkVert from '../../../../assets/checkVert.svg';
import { noLevelId } from 'src/pages/Fiche Projet/Settings Projet/Sub Pages/OnSiteLocations';
import { transformString } from 'src/useful/UsefulFunctions';
import styles from './LocationsDropDown.module.css';

ItemLevel.propTypes = {
	level: PropTypes.object.isRequired,
	locations: PropTypes.array.isRequired,
	selectedLocations: PropTypes.array.isRequired,
	setSelectedLocations: PropTypes.func.isRequired,
	research: PropTypes.string,
};

export default function ItemLevel({ level, locations, selectedLocations, setSelectedLocations, research = '' }) {
	const isSelected =
		selectedLocations?.includes(level.id) ||
		locations?.some((location) => selectedLocations?.includes(location.id));

	const [isCollapsed, setIsCollapsed] = useState(!isSelected && research === '');
	const [insideResearch, setInsideResearch] = useState(research);

	useEffect(() => {
		setIsCollapsed(!isSelected && research === '');
	}, [isSelected, research]);

	useEffect(() => {
		setInsideResearch(research);
	}, [research]);

	return (
		<div>
			<div
				className={styles.itemLevel}
				onClick={() => {
					setIsCollapsed(!isCollapsed);
					if (level.id !== noLevelId && !isSelected) {
						setSelectedLocations([level.id]);
					}
					setInsideResearch('');
				}}>
				<img
					src={isSelected ? triangleVert : triangle}
					alt=""
					className={styles.triangle}
					style={{ transform: isCollapsed && 'rotate(-90deg)' }}
				/>
				<p style={{ color: isSelected && '#429C1E' }}>{level.name}</p>

				{isSelected && <img src={checkVert} alt="" className={styles.checkVert} />}
			</div>

			{!isCollapsed && (
				<div>
					{locations
						?.filter((location) => transformString(location.name).includes(transformString(insideResearch)))
						?.map((location) => (
							<ItemLocation
								key={location.id}
								name={location.name}
								isSelected={selectedLocations?.includes(location.id)}
								onClick={() => {
									setSelectedLocations([location.id]);
								}}
							/>
						))}
				</div>
			)}
		</div>
	);
}

function ItemLocation({ name, isSelected, onClick }) {
	return (
		<div className={styles.itemLocation} style={{ color: isSelected && '#429C1E' }} onClick={onClick}>
			{name}
			{isSelected && <img src={checkVert} alt="" className={styles.checkVert} />}
		</div>
	);
}

ItemLocation.propTypes = {
	name: PropTypes.string.isRequired,
	isSelected: PropTypes.bool.isRequired,
	onClick: PropTypes.func.isRequired,
};
