import React, { useContext, useEffect, useState } from 'react';
import { transformString } from '../../../../useful/UsefulFunctions';
import LockIcon from '../../../../assets/LockIcon.png';
import folderEdit from '../../../../assets/folderEdit.png';
import chargement from '../../../../assets/chargement.png';
import { useTranslation } from 'react-i18next';
import AuthDataContext from '../../../../contexts/authDataContext';
import '../_finances.scss';
import { useClientsContext } from 'src/contexts/clientsContext';
import AgencyContext from 'src/contexts/agencyContext';

export default function ExpenseItem({
	expenseData,
	ttc,
	research,
	mandataire,
	setModalModifPosteDepense,
	setSelectedExpenseData,
	categIndex,
	expenseIndex,
	type,
	isModeLecture,
	currency,
	canModify,
}) {
	const { t } = useTranslation();

	const agency = useContext(AgencyContext);
	const authData = useContext(AuthDataContext);
	const [clients] = useClientsContext();
	const [isAdmin, setIsAdmin] = useState(localStorage.getItem('administrator') === 'true');

	useEffect(() => {
		if (authData) {
			setIsAdmin(authData.subtype === 'administrator');
		}
	}, [authData]);

	const formatter = new Intl.NumberFormat('fr-FR', {
		style: 'currency',
		currency: currency
			? currency === '€'
				? 'EUR'
				: currency === '$'
					? 'USD'
					: currency === '£'
						? 'GBP'
						: currency
			: 'EUR',
		minimumFractionDigits: 0,
	});

	const ExpenseInfoContent = ({ isConfidential }) => (
		<div className="containerExpenseInfos">
			{isConfidential ? (
				<>
					<div style={{ marginBottom: -13 }} className="containerHiddenInfo">
						<p className="previsionPosteDepense">{t('modal_edit_expense_item.estimated_budget')} :</p>
						<div className="hiddenInfo" />
					</div>
					<div className="containerHiddenInfo">
						<p className="payePosteDepense">{t('modal_edit_expense_item.amount_paid')} :</p>
						<div className="hiddenInfo" />
					</div>
				</>
			) : (
				<>
					<p className="previsionPosteDepense">
						{t('modal_edit_expense_item.estimated_budget')} : {formatter.format(expenseData.estimate ?? 0)}{' '}
						{ttc ? t('modal_edit_expense_item.ttc') : t('modal_edit_expense_item.ht')}
					</p>
					<p className="payePosteDepense">
						{t('modal_edit_expense_item.amount_paid')} : {formatter.format(expenseData.paid ?? 0)}{' '}
						{ttc ? t('modal_edit_expense_item.ttc') : t('modal_edit_expense_item.ht')}
					</p>
				</>
			)}
		</div>
	);

	return (
		<>
			{expenseData ? (
				transformString(expenseData.name).includes(transformString(research)) && !expenseData.dummy ? (
					<div className={'posteDepense'}>
						<div className={'containerNameExpense'}>
							{type !== 'clients' && expenseData?.isConfidential && (
								<img src={LockIcon} alt="" className="confidentialIcon" />
							)}

							<p
								className={'nomPosteDepense'}
								style={{ maxWidth: type !== 'clients' && expenseData?.isConfidential && 210 }}>
								{expenseData.name ? expenseData.name : t('finances.category_name')}
							</p>

							{type !== 'clients' && expenseData?.isConfidential && (
								<p className={'confidentialText'}>{t('activity.confidential')}</p>
							)}
						</div>

						<div className={'containerBarPercentage'}>
							<div className={'backBarPosteDepense'}>
								<div
									className={'frontBarPosteDepense'}
									style={{
										width: `calc(370px * ${
											!expenseData.isConfidential ||
											(isAdmin && mandataire) ||
											type === 'clients' ||
											clients?.map((item) => item.id).includes(agency)
												? expenseData.paid
													? !expenseData.estimate && expenseData.paid > 0
														? 1
														: expenseData.paid / expenseData.estimate > 1
															? 1
															: expenseData.paid / expenseData.estimate
													: 0
												: 0
										})`,
									}}
								/>
							</div>
							<div className={'pourcentagePosteDepense'}>
								{!expenseData.isConfidential ||
								(isAdmin && mandataire) ||
								type === 'clients' ||
								clients?.map((item) => item.id).includes(agency) ? (
									<p>
										{expenseData.paid
											? !expenseData.estimate && expenseData.paid > 0
												? 999
												: Math.round((100 * expenseData.paid) / expenseData.estimate) > 999
													? 999
													: Math.round((100 * expenseData.paid) / expenseData.estimate)
											: 0}{' '}
										%
									</p>
								) : (
									<div className={'containerHiddenPercentage'}>
										<div className={'hiddenPercentage'} />
										<p> %</p>
									</div>
								)}
							</div>

							{((isAdmin && mandataire) ||
								type === 'clients' ||
								clients?.map((item) => item.id).includes(agency) ||
								!expenseData?.isConfidential) && (
								<div
									className={'modifierPoste'}
									onClick={() => {
										setModalModifPosteDepense(true);
										setSelectedExpenseData({ ...expenseData, categIndex, expenseIndex });
									}}>
									<img src={folderEdit} alt="" className={'folderEditIcon'} />
								</div>
							)}
						</div>

						{(isAdmin && mandataire) ||
						type === 'clients' ||
						clients?.map((item) => item.id).includes(agency) ||
						!expenseData?.isConfidential ? (
							<ExpenseInfoContent isConfidential={false} />
						) : (
							<ExpenseInfoContent isConfidential={true} />
						)}
					</div>
				) : (
					<div />
				)
			) : (
				<div className={'chargementContainer'}>
					<img src={chargement} alt="" className={'chargement'} />
				</div>
			)}
		</>
	);
}
