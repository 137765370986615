import React, { useState, useContext, useEffect, useMemo, useRef } from 'react';
import './_ticketsPage.scss';
import TicketsHeader from './components/Tickets Header/TicketsHeader';
import TicketsColumn from './components/Tickets Column/TicketsColumn';
import ModalTicket from './components/Modal Ticket/ModalTicket';
import { generateUniqueFirestoreId } from '../../../firebase/utils';
import { createdByConstructor } from '../utils';
import { auth } from '../../../firebase/config';
import TypeContext from '../../../contexts/typeContext';
import AgencyContext from '../../../contexts/agencyContext';
import { firestore } from '../../../firebase/config';
import { joinPaths } from '../../../firebase/utils';
import { Paths } from '../../../firebase/paths';
import { useProjectContext } from '../../../contexts/projectContext';
import { useTranslation } from 'react-i18next';
import { TICKET_LOG_TYPE } from './data';
import { arrayUnion, FieldValue, increment } from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
import chargement from '../../../assets/chargement.png';
import { TICKET_STATUS } from './components/Modal Ticket/Follow Up/FollowUp';
import { getMissionStatus, getTicketStatus } from './utils';
import { useClientsContext } from 'src/contexts/clientsContext';
import { useProjectUsersContext } from 'src/contexts/projectUsersContext';
import PropTypes from 'prop-types';
import AuthDataContext from 'src/contexts/authDataContext';

Tickets.propTypes = {
	isSnagging: PropTypes.bool.isRequired,
	isReadOnly: PropTypes.bool.isRequired,
	handleModifyLots: PropTypes.func.isRequired,
	handleModifyLocations: PropTypes.func.isRequired,
};

export default function Tickets({ isSnagging, isReadOnly, handleModifyLots, handleModifyLocations }) {
	const { t } = useTranslation();

	const uid = auth.currentUser?.uid;
	const userType = useContext(TypeContext);
	const agencyId = useContext(AgencyContext);
	const [project] = useProjectContext();
	const [clients] = useClientsContext();
	const [users] = useProjectUsersContext();
	const authData = useContext(AuthDataContext);

	const [tickets, setTickets] = useState([]);
	const [selectedTicketId, setSelectedTicketId] = useState(null);

	const [modalTicket, setModalTicket] = useState(false);
	const [dataLoading, setDataLoading] = useState(true);

	const [activeSubPage, setActiveSubPage] = useState('board');

	// ========================================= Sort and Filter Options =========================================

	const [filterSortOpened, setFilterSortOpened] = useState(false);

	const [selectedSort, setSelectedSort] = useState([]);

	const [companyInvolvedSelected, setCompanyInvolvedSelected] = useState([]);
	const [lotsConcernedSelected, setLotsConcernedSelected] = useState([]);
	const [locationOnSiteSelected, setLocationOnSiteSelected] = useState([]);
	const [rolesSelected, setRolesSelected] = useState([]);
	const [statusSelected, setStatusSelected] = useState([]);
	const [visibilitySelected, setVisibilitySelected] = useState([]);

	const filteredTickets = useMemo(() => {
		const isClient = userType === 'clients' || clients?.map((client) => client.id)?.includes(agencyId);
		return tickets?.filter((ticket) => {
			return (
				(ticket.properties?.owner?.userId === uid ||
					(((ticket.properties.type === 'issue' &&
						(ticket.properties?.owner?.agencyId === agencyId ||
							ticket.properties?.companies?.includes(agencyId) ||
							ticket.properties?.reviewers?.includes(agencyId))) ||
						ticket.properties?.users?.includes(uid)) &&
						ticket.properties?.visibility !== 'private') ||
					(isClient &&
						(ticket.properties?.companies?.includes('clients') ||
							ticket.properties?.reviewers?.includes('clients') ||
							ticket.properties?.users?.includes('clients') ||
							ticket.properties?.owner?.userType === 'client' ||
							clients?.map((item) => item.id).includes(ticket.properties.owner?.agencyId)))) &&
				(companyInvolvedSelected.length < 1 ||
					companyInvolvedSelected.some(
						(company) =>
							ticket.properties?.owner?.agencyId === company ||
							ticket.properties?.companies?.includes(company) ||
							ticket.properties?.reviewers?.includes(company) ||
							(company === 'clients' &&
								(ticket.properties?.users?.includes(company) ||
									ticket.properties?.owner?.userType === 'client' ||
									clients?.map((item) => item.id)?.includes(ticket.properties?.owner?.agencyId)))
					)) &&
				(lotsConcernedSelected.length < 1 ||
					lotsConcernedSelected.some((lot) => ticket.properties?.lots?.includes(lot))) &&
				(locationOnSiteSelected.length < 1 ||
					locationOnSiteSelected.some((location) => ticket.properties?.locations?.includes(location))) &&
				(rolesSelected.length < 1 || rolesSelected.some((role) => ticketHasRole(ticket, role))) &&
				(statusSelected.length < 1 ||
					statusSelected.some((status) =>
						ticket.properties.type === 'issue'
							? getTicketStatus(ticket, isClient ? 'clients' : agencyId) === status
							: getMissionStatus(ticket) === status
					)) &&
				(visibilitySelected.length < 1 ||
					visibilitySelected.some((visibility) => ticket.properties?.visibility === visibility))
			);
		});
	}, [
		tickets,
		companyInvolvedSelected,
		lotsConcernedSelected,
		locationOnSiteSelected,
		uid,
		agencyId,
		rolesSelected,
		statusSelected,
		visibilitySelected,
		userType,
		clients,
	]);

	const sortedTickets = useMemo(() => {
		const sorted = [...filteredTickets];

		sorted.sort((a, b) => a.createdAt.localeCompare(b.createdAt));

		[...selectedSort].reverse().forEach((option) => {
			sorted.sort((a, b) => (option.order === 'asc' ? option.function(a, b) : option.function(b, a)));
		});

		return sorted;
	}, [filteredTickets, selectedSort]);

	function ticketHasRole(ticket, role) {
		const isClient = userType === 'clients' || clients?.map((client) => client.id)?.includes(agencyId);
		if (role === 'creator')
			return (
				ticket.properties?.owner?.agencyId === agencyId ||
				ticket.properties?.owner?.userId === uid ||
				(isClient &&
					(ticket.properties?.owner?.userType === 'client' ||
						clients?.map((client) => client.id)?.includes(ticket.properties?.owner?.agencyId)))
			);
		if (role === 'company')
			return (
				ticket.properties?.companies?.includes(agencyId) ||
				(isClient && ticket.properties?.companies?.includes('clients'))
			);
		if (role === 'user')
			return (
				ticket.properties?.users?.includes(uid) || (isClient && ticket.properties?.users?.includes('clients'))
			);
		if (role === 'reviewer')
			return (
				ticket.properties?.reviewers?.includes(agencyId) ||
				(isClient && ticket.properties?.reviewers?.includes('clients'))
			);
		return false;
	}

	// ========================================= Get Tickets =========================================

	useEffect(() => {
		if (project.id) {
			if (!isReadOnly || isSnagging) {
				const unsubscribe = firestore
					.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS))
					.where('properties.type', '==', isSnagging ? 'issue' : 'mission')
					.onSnapshot((snapshot) => {
						const ticketsData = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
						setTickets(ticketsData);
						setDataLoading(false);
					});
				return () => unsubscribe();
			} else {
				setDataLoading(false);
			}
		}
	}, [project.id, isSnagging, isReadOnly]);

	// ========================================= Create Ticket =========================================

	const handleCreateTicket = async (type) => {
		mixpanel.track('Work Created', {
			'Project ID': project.id,
			Page: 'workspace',
			'Work Type': type,
		});

		const id = generateUniqueFirestoreId();
		const data = {
			id,
			status: 'ongoing',
			name: type === 'mission' ? t('translation.newMission') : t('translation.newSnag'),
			properties: {
				visibility: 'collaborative',
				type,
				owner: createdByConstructor(uid, userType, agencyId),
				issueAt: new Date().toISOString(),
				reviewers:
					userType === 'clients' || clients?.map((item) => item.id)?.includes(agencyId)
						? ['clients']
						: [agencyId],
				users:
					type === 'mission' ? users?.filter((user) => user.agency === agencyId)?.map((user) => user.id) : [],
			},
			createdBy: createdByConstructor(uid, userType, agencyId),
			createdAt: new Date().toISOString(),
			modifiedAt: new Date().toISOString(),
			companiesViewed:
				userType === 'clients'
					? ['clients', uid]
					: clients?.map((item) => item.id)?.includes(agencyId)
						? ['clients', agencyId, uid]
						: [agencyId, uid],
			issueStatus: TICKET_STATUS.inProgress,
			missionStatus: 'ongoing',
		};
		setTickets((prev) => [...prev, data]);
		setSelectedTicketId(id);
		setModalTicket(true);

		await firestore.runTransaction(async (transaction) => {
			const countersRef = firestore.doc(joinPaths(Paths.COUNTERS, project.id));
			const counters = await transaction.get(countersRef);

			if (!counters.exists) {
				transaction.set(countersRef, {
					snags: 1,
					missions: 1,
				});
			} else {
				transaction.update(countersRef, {
					snags: increment(1),
					missions: increment(1),
				});
			}

			transaction.set(firestore.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS)).doc(id), {
				...data,
				index: (counters.data()?.snags ?? 0) + 1,
			});
		});

		firestore
			.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, id, Paths.CONTENT))
			.doc(id)
			.set({
				type: 'text',
				title: t('translation.description'),
				text: '',
				createdBy: createdByConstructor(uid, userType, agencyId),
				createdAt: new Date().toISOString(),
				modifiedAt: new Date().toISOString(),
			});
		if (type === 'issue') {
			firestore.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, id, Paths.LOGS)).add({
				type: TICKET_LOG_TYPE.created,
				name: authData.surname + ' ' + authData.name,
				createdAt: new Date().toISOString(),
				createdBy: createdByConstructor(uid, userType, agencyId),
			});
		}
	};

	// ========================================= Select Ticket =========================================

	const handleSelectTicket = (id) => {
		setSelectedTicketId(id);
		setModalTicket(true);
		if (uid && (userType === 'clients' || agencyId)) {
			firestore.doc(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, id)).update({
				companiesViewed:
					userType === 'clients'
						? arrayUnion('clients', uid)
						: clients?.map((item) => item.id)?.includes(agencyId)
							? arrayUnion('clients', agencyId, uid)
							: arrayUnion(agencyId, uid),
				companiesViewedRefused:
					userType === 'clients'
						? arrayUnion('clients', uid)
						: clients?.map((item) => item.id)?.includes(agencyId)
							? arrayUnion('clients', agencyId, uid)
							: arrayUnion(agencyId, uid),
			});
		}
	};

	// ================================================ Scroll Left ================================================

	const containerRef = useRef(null);
	const [scrollLeft, setScrollLeft] = useState(0);

	useEffect(() => {
		const handleScroll = () => {
			if (containerRef.current) {
				setScrollLeft(containerRef.current.scrollLeft);
			}
		};

		const element = containerRef.current;
		element?.addEventListener('scroll', handleScroll);

		return () => element?.removeEventListener('scroll', handleScroll);
	}, []);

	// ========================================= Render =========================================

	return (
		<div className={'ticketsPage'}>
			<TicketsHeader
				isSnagging={isSnagging}
				// Filter and Sort
				filterSortOpened={filterSortOpened}
				setFilterSortOpened={setFilterSortOpened}
				selectedSort={selectedSort}
				setSelectedSort={setSelectedSort}
				companyInvolvedSelected={companyInvolvedSelected}
				setCompanyInvolvedSelected={setCompanyInvolvedSelected}
				lotsConcernedSelected={lotsConcernedSelected}
				setLotsConcernedSelected={setLotsConcernedSelected}
				locationOnSiteSelected={locationOnSiteSelected}
				setLocationOnSiteSelected={setLocationOnSiteSelected}
				rolesSelected={rolesSelected}
				setRolesSelected={setRolesSelected}
				statusSelected={statusSelected}
				setStatusSelected={setStatusSelected}
				visibilitySelected={visibilitySelected}
				setVisibilitySelected={setVisibilitySelected}
				// Functions
				handleCreateTicket={handleCreateTicket}
				tickets={filteredTickets?.sort(
					(a, b) =>
						new Date(a.properties?.issueAt ?? '').getTime() -
						new Date(b.properties?.issueAt ?? '').getTime()
				)}
				activeSubPage={activeSubPage}
				setActiveSubPage={setActiveSubPage}
			/>

			{!dataLoading ? (
				isReadOnly && !isSnagging ? (
					<div className={'containerInfoLectureSeule'}>
						<span>{t('translation.youDontHaveRequiredAuth')}</span>
						<span>{t('translation.joinTheProjectToaAccessMissions')}</span>
					</div>
				) : (
					<div
						className={
							filterSortOpened ? 'containerTicketsColumns filterSortOpened' : 'containerTicketsColumns'
						}
						ref={containerRef}>
						<TicketsColumn
							key={isSnagging ? 0 : 1}
							sortedTickets={sortedTickets}
							type={isSnagging ? 'issue' : 'mission'}
							handleCreateTicket={handleCreateTicket}
							handleSelectTicket={handleSelectTicket}
							isSnagging={isSnagging}
							activeSubPage={activeSubPage}
							scrollLeft={scrollLeft}
							filterSortOpened={filterSortOpened}
						/>

						<div style={{ display: 'flex' }}>
							<div style={{ width: 1, height: 20 }} />
						</div>
					</div>
				)
			) : (
				<div className={'chargementContainer'}>
					<img src={chargement} alt="" className={'chargement'} />
				</div>
			)}

			<ModalTicket
				isSnagging={isSnagging}
				handleModifyLots={handleModifyLots}
				handleModifyLocations={handleModifyLocations}
				isOpen={modalTicket}
				data={tickets?.find((ticket) => ticket.id === selectedTicketId)}
				onClose={() => {
					setModalTicket(false);
					setTimeout(() => {
						setSelectedTicketId(null);
					}, 100);
				}}
			/>
		</div>
	);
}
