import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import './_widgetUpload.scss';
import uploadIcon from '../../assets/uploadIcon.svg';
import whiteUp from '../../assets/whiteUp.svg';
import blackCrossIcon from '../../assets/blackCrossIcon.svg';
import bottomArrow from '../../assets/bottomArrow.svg';
import doneWhite from '../../assets/doneWhite.svg';
import whiteCross from '../../assets/whiteCross.svg';
import ItemWidgetUpload from './ItemWidgetUpload';
import { useTranslation } from 'react-i18next';

const WidgetUpload = ({ progressFiles, setProgressFiles }) => {
	const { t } = useTranslation();

	const [isHidden, setIsHidden] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [lastCompleteUploadTime, setLastCompleteUploadTime] = useState(null);

	const startTimesRef = useRef({});

	useEffect(() => {
		const now = Date.now();
		const newStartTimes = { ...startTimesRef.current };
		let hasChanges = false;

		Object.keys(progressFiles).forEach((id) => {
			if (!newStartTimes[id]) {
				newStartTimes[id] = now;
				hasChanges = true;
			}
		});

		if (hasChanges) {
			startTimesRef.current = newStartTimes;
		}
	}, [progressFiles]);

	const calculateSpeed = useCallback((bytesTransferred, startTime) => {
		const now = Date.now();
		const elapsedTime = (now - startTime) / 1000;
		const effectiveTime = Math.max(elapsedTime, 0.1);
		return (bytesTransferred * 8) / effectiveTime;
	}, []);

	const downloadSpeed = useMemo(() => {
		const now = Date.now();
		const speeds = Object.entries(progressFiles)
			.filter(([_, file]) => {
				const id = _;
				const startTime = startTimesRef.current[id] || now;
				if (lastCompleteUploadTime && startTime < lastCompleteUploadTime) {
					return false;
				}
				const elapsedTime = (now - startTime) / 1000;
				return (
					file.bytesTransferred > 0 &&
					(file.bytesTransferred < file.totalBytes ||
						(file.bytesTransferred === file.totalBytes && elapsedTime <= 2))
				);
			})
			.map(([id, file]) => calculateSpeed(file.bytesTransferred, startTimesRef.current[id] || now));

		if (speeds.length === 0 && Object.keys(progressFiles).length > 0) return 1024;
		return speeds.reduce((a, b) => a + b, 0) / Math.max(speeds.length, 1);
	}, [progressFiles, calculateSpeed, lastCompleteUploadTime]);

	const formatSpeed = (speed) => {
		if (speed < 1024) return `${speed.toFixed(2)} b/s`;
		if (speed < 1024 * 1024) return `${(speed / 1024).toFixed(2)} Kb/s`;
		return `${(speed / (1024 * 1024)).toFixed(2)} Mb/s`;
	};

	const progressFilesIds = useMemo(() => {
		const now = Date.now();
		return Object.keys(progressFiles)
			.filter((id) => {
				const startTime = startTimesRef.current[id] || now;
				return !lastCompleteUploadTime || startTime >= lastCompleteUploadTime;
			})
			.reverse();
	}, [progressFiles, lastCompleteUploadTime]);
	const progressFilesLength = useMemo(() => progressFilesIds.length, [progressFilesIds]);
	const progressFilesLengthDone = useMemo(
		() =>
			progressFilesIds.filter((id) => progressFiles[id].bytesTransferred === progressFiles[id].totalBytes).length,
		[progressFilesIds, progressFiles]
	);

	const inProgress = useMemo(
		() => progressFilesLengthDone < progressFilesLength,
		[progressFilesLengthDone, progressFilesLength]
	);
	const progressBar = useMemo(() => {
		if (!progressFilesIds.length) return 0;

		const { bytesTransferred, totalBytes } = progressFilesIds.reduce(
			(acc, id) => {
				const file = progressFiles[id];
				const transferred = Math.max(0, file.bytesTransferred || 0);
				const total = Math.max(0, file.totalBytes || 0);

				return {
					bytesTransferred: acc.bytesTransferred + transferred,
					totalBytes: acc.totalBytes + total,
				};
			},
			{ bytesTransferred: 0, totalBytes: 0 }
		);

		return totalBytes > 0 ? Math.min(100, Math.max(0, (bytesTransferred / totalBytes) * 100)) : 0;
	}, [progressFiles, progressFilesIds]);

	useEffect(() => {
		if (progressFiles && Object.keys(progressFiles).length > 0) {
			setIsHidden(false);
		} else {
			setIsHidden(true);
		}
	}, [progressFiles]);

	useEffect(() => {
		if (progressFilesIds.length > 0 && progressFilesLengthDone === progressFilesLength) {
			setLastCompleteUploadTime(Date.now());
		}
	}, [progressFilesLengthDone, progressFilesLength, progressFilesIds.length]);

	const closeWidget = () => {
		setIsHidden(true);
		setProgressFiles({});
	};

	return (
		<>
			{!isHidden && (
				<div className={isOpen ? 'widgetUploadOpen' : 'widgetUpload'}>
					{isOpen && (
						<div className={'headerUpload'}>
							<p className={'imports'}>{t('translation.imports')}</p>
							<div className={'containerButtons'}>
								<div className={'buttonCross'} onClick={() => setIsOpen(false)}>
									<img src={bottomArrow} alt="" className={'bottomArrow'} />
								</div>
								<div className={'buttonCross'} onClick={closeWidget}>
									<img src={blackCrossIcon} alt="" className={'blackCrossIcon'} />
								</div>
							</div>
						</div>
					)}

					{isOpen && (
						<div className={'listDocument'}>
							{progressFiles &&
								progressFilesIds.map((id) => <ItemWidgetUpload key={id} data={progressFiles[id]} />)}
						</div>
					)}

					{inProgress ? (
						<div className={'footerUploadInProgress'}>
							<div className={'iconPart'}>
								<img src={uploadIcon} alt="" className={'uploadIcon'} />
							</div>

							<div className={'progressPart'}>
								<div className={'textButton'} style={{ zIndex: 4 }}>
									<p className={'text'}>
										{t('translation.importOf')} {progressFilesLengthDone}{' '}
										{t('translation.elementLittle')}
										{progressFilesLengthDone > 1 && 's'} {t('translation.on')} {progressFilesLength}
										<div className={'speedDisplay'}>
											{t('translation.importSpeed')} {formatSpeed(downloadSpeed)}
										</div>
									</p>
									{!isOpen && (
										<div className={'buttonUp'} onClick={() => setIsOpen(true)}>
											<img src={whiteUp} alt="" className={'whiteUp'} />
										</div>
									)}
								</div>
								<div
									className={'progressBar'}
									style={{ zIndex: 0, width: (330 * progressBar) / 100 }}
								/>
							</div>
						</div>
					) : (
						<div className={'footerUploadInProgress'} style={{ backgroundColor: '#3B8441' }}>
							<div className={'iconPart'}>
								<img src={doneWhite} alt="" className={'uploadIcon'} style={{ width: 20 }} />
							</div>
							<div className={'progressPart'}>
								<div className={'textButton'} style={{ zIndex: 4 }}>
									<div>
										<p className={'text'} style={{ fontSize: 14 }}>
											{t('translation.successTransfer')}
										</p>
										<p className={'text'} style={{ fontSize: 10, marginTop: 2 }}>
											{t('translation.importSuccess')}
										</p>
									</div>

									{!isOpen && (
										<div style={{ display: 'flex' }}>
											<div className={'buttonUp'} onClick={() => setIsOpen(true)}>
												<img src={whiteUp} alt="" className={'whiteUp'} />
											</div>
											<div className={'buttonUp'} onClick={closeWidget}>
												<img src={whiteCross} alt="" className={'whiteCross'} />
											</div>
										</div>
									)}
								</div>
								<div
									className={'progressBar'}
									style={{ zIndex: 0, backgroundColor: '#5AA260', width: 330 }}
								/>
							</div>
						</div>
					)}
				</div>
			)}
		</>
	);
};

export default WidgetUpload;
