import React, { useContext, useEffect, useState } from 'react';
import './_contributorAccountsContainer.scss';
import plusGreen from '../../../../assets/plusGreen.svg';
import ModalClientsAccount from './Modaux/ModalClientsAccount';
import ModalCollabsAccounts from './Modaux/ModalCollabsAccounts';
import ModalPartnerCompany from './Modaux/ModalPartnerCompany';
import ModalNewClient from './Modaux/ModalNewClient';
import ModalNewCollab from './Modaux/ModalNewCollab';
import ModalNewPartnerCompany from './Modaux/ModalNewPartnerCompany';
import { useTranslation } from 'react-i18next';
import { auth, firestore } from '../../../../firebase/config';
import AgencyContext from '../../../../contexts/agencyContext';
import AgencyDataContext from '../../../../contexts/agencyDataContext';
import ContainerGroupContributors from './ContainerGroupContributors';
import { rolesWithMOA } from '../../utils';
import AddEntreprisesComponent from '../AddEntreprisesComponent';
import AddClientsComponent from '../AddClientsComponent';

export default function ContributorAccountsContainer({
	projectId,
	isMandataire,
	projectIsActive,
	projectName,
	projectTag,
	mandataireUid,
	isModeLecture,
}) {
	const [countPartnerCompanies, setCountPartnerCompanies] = useState(0);

	const { t } = useTranslation();

	const uid = auth.currentUser?.uid;
	const agency = useContext(AgencyContext);
	const agencyData = useContext(AgencyDataContext);

	// Data

	const [actors, setActors] = useState([]);
	const [collaborators, setCollaborators] = useState([]);
	const [clients, setClients] = useState([]);
	const [partnerAgencies, setPartnerAgencies] = useState([]);
	const [selectedAgencyData, setSelectedAgencyData] = useState(null);

	const numberExteriorActors = actors.filter(
		(item) => item.type === 'agencies' && item.uid !== agency && !item.roleIds?.includes(rolesWithMOA(t)[0].id)
	).length;

	// Modaux

	const [modalClientsAccounts, setModalClientsAccounts] = useState(false);
	const [modalCollabsAccounts, setModalCollabsAccounts] = useState(false);
	const [modalPartnerCompany, setModalPartnerCompany] = useState(false);

	const [agencyUidModal, setAgencyUidModal] = useState(null);

	// Research

	const [research, setResearch] = useState('');
	const [researchNumber, setResearchNumber] = useState(0);

	const [modalNewClient, setModalNewClient] = useState(false);
	const [modalNewCollab, setModalNewCollab] = useState(false);
	const [modalNewPartnerCompany, setModalNewPartnerCompany] = useState(false);

	useEffect(() => {
		const subscriber1 = firestore.collection(`projects/${projectId}/accounts`).onSnapshot((querySnapshot) => {
			if (querySnapshot) {
				const actors = [];
				querySnapshot.forEach((documentSnapshot) => {
					if (documentSnapshot && documentSnapshot.exists) {
						actors.push({ ...documentSnapshot.data(), id: documentSnapshot.id });
					}
				});
				setActors(actors);
			}
		});
		firestore
			.collection(`agencies/${agency}/collaborators`)
			.get()
			.then((querySnapshot) => {
				if (querySnapshot) {
					const collaborators = [];
					querySnapshot.forEach((documentSnapshot) => {
						if (documentSnapshot && documentSnapshot.exists && !documentSnapshot.data().isDeleted) {
							collaborators.push({ ...documentSnapshot.data(), id: documentSnapshot.id });
						}
					});
					setCollaborators(collaborators);
				}
			});
		firestore
			.collection(`agencies/${agency}/clients`)
			.get()
			.then((querySnapshot) => {
				if (querySnapshot) {
					const clients = [];
					querySnapshot.forEach((documentSnapshot) => {
						if (documentSnapshot && documentSnapshot.exists) {
							clients.push(documentSnapshot.data());
						}
					});
					setClients(clients);
				}
			});
		firestore
			.collection(`agencies/${agency}/partnerAgencies`)
			.get()
			.then((querySnapshot) => {
				if (querySnapshot) {
					const partnerAgencies = [];
					querySnapshot.forEach((documentSnapshot) => {
						if (documentSnapshot && documentSnapshot.exists) {
							partnerAgencies.push(documentSnapshot.data());
						}
					});
					setPartnerAgencies(partnerAgencies);
				}
			});
		return () => {
			subscriber1();
		};
	}, [projectId, agency, uid]);

	return (
		<div className={'contributorAccountsContainer'}>
			<ContainerGroupContributors
				agence={true}
				agenceMandataire={mandataireUid === agency}
				vous={true}
				agencyId={agency}
				titreIntervenant={
					actors &&
					actors.filter((item) => item.uid === agency)[0] &&
					actors.filter((item) => item.uid === agency)[0].title
				}
				accounts={collaborators.filter((item) => actors.map((item) => item.uid).includes(item.uid))}
				noIntervenant={actors && actors.length === 0}
				research={research}
				setResearchNumber={setResearchNumber}
				isMandataire={isMandataire}
				projectId={projectId}
				setModalNewCollab={setModalNewCollab}
				setModalCollabsAccounts={setModalCollabsAccounts}
				setAgencyUidModal={setAgencyUidModal}
			/>

			{!isMandataire && !isModeLecture && actors.filter((item) => item.type === 'clients').length === 0 ? null : (
				<div style={{ display: 'flex' }}>
					<div className={'bar'} />
				</div>
			)}

			{!isMandataire && !isModeLecture && actors.filter((item) => item.type === 'clients').length === 0 ? null : (
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<div className={'secondaryTitle'}>{t('translation.clientOfTheProject')}</div>
				</div>
			)}

			{actors.filter((item) => item.type === 'clients').length > 0 && (
				<ContainerGroupContributors
					agence={false}
					vous={false}
					client={true}
					titreIntervenant={t('contributors.contracting_owner')}
					accounts={actors.filter((item) => item.type === 'clients')}
					noIntervenant={actors && actors.length === 0}
					research={research}
					setResearchNumber={setResearchNumber}
					setmodalNewClient={setModalNewClient}
					agencyId={agency}
					projectId={projectId}
					agenceMandataire={mandataireUid === agency}
					isMandataire={isMandataire}
					modalClientsAccounts={modalClientsAccounts}
					setModalClientsAccounts={setModalClientsAccounts}
					setModalNewClient={setModalNewClient}
					setModalCollabsAccounts={setModalCollabsAccounts}
					setAgencyUidModal={setAgencyUidModal}
				/>
			)}

			{actors
				.filter(
					(item) =>
						item.type === 'agencies' && item.uid !== agency && item.roleIds?.includes(rolesWithMOA(t)[0].id)
				)
				.map((item, index) => {
					return (
						<ContainerGroupContributors
							key={index}
							agence={true}
							agenceMandataire={mandataireUid === item.uid}
							agencyId={item.uid}
							titreIntervenant={item.title}
							accounts={actors.filter((actor) => item.uid === actor.agency)}
							projectId={projectId}
							noIntervenant={actors && actors.length === 0}
							research={research}
							setResearchNumber={setResearchNumber}
							isMandataire={isMandataire}
							setModalPartnerCompany={setModalPartnerCompany}
							setAgencyUidModal={setAgencyUidModal}
						/>
					);
				})}

			{isMandataire &&
				!isModeLecture &&
				actors.filter(
					(item) =>
						item.type === 'agencies' && item.uid !== agency && item.roleIds?.includes(rolesWithMOA(t)[0].id)
				).length < 1 &&
				actors.filter((item) => item.type === 'clients').length < 1 && (
					<AddClientsComponent setModalNewClient={setModalNewClient} />
				)}

			<div style={{ display: 'flex' }}>
				<div className={'bar'} />
			</div>

			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<div className={'secondaryTitle'}>{t('translation.enterprisesContributors')}</div>
				{numberExteriorActors > 1 && isMandataire && !isModeLecture && (
					<div className={'buttonAddEntreprise hover'} onClick={() => setModalNewPartnerCompany(true)}>
						<img src={plusGreen} alt="" className={'icon'} />
						{t('translation.addEnterprisesToProject')}
					</div>
				)}
			</div>

			{isModeLecture &&
				actors.filter(
					(item) =>
						item.type === 'agencies' &&
						item.uid !== agency &&
						!item.roleIds?.includes(rolesWithMOA(t)[0].id)
				).length === 0 && <p className={'noAccounts'}>{t('translation.noClients')}</p>}

			{actors
				.filter(
					(item) =>
						item.type === 'agencies' &&
						item.uid !== agency &&
						!item.roleIds?.includes(rolesWithMOA(t)[0].id)
				)
				.map((item, index) => {
					return (
						<ContainerGroupContributors
							key={index}
							agence={true}
							exteriorCompany={true}
							index={index}
							agenceMandataire={mandataireUid === item.uid}
							agencyId={item.uid}
							titreIntervenant={item.title}
							accounts={actors.filter((actor) => item.uid === actor.agency)}
							projectId={projectId}
							noIntervenant={actors && actors.length === 0}
							research={research}
							setResearchNumber={setResearchNumber}
							isMandataire={isMandataire}
							setModalPartnerCompany={setModalPartnerCompany}
							setAgencyUidModal={setAgencyUidModal}
							seatType={'Monthly Seat'}
						/>
					);
				})}

			{numberExteriorActors < 2 && isMandataire && !isModeLecture && (
				<AddEntreprisesComponent
					companiesLeft={2 - numberExteriorActors}
					setModalNewPartnerCompany={setModalNewPartnerCompany}
				/>
			)}

			<div style={{ display: 'flex' }}>
				<div style={{ zIndex: 1 }}>
					<ModalCollabsAccounts
						isMandataire={isMandataire}
						modalCollabsAccounts={modalCollabsAccounts}
						setModalCollabsAccounts={setModalCollabsAccounts}
						setModalNewCollab={setModalNewCollab}
						uid={agencyUidModal}
						projectId={projectId}
						vous={true}
						accounts={collaborators.filter((item) => actors.map((item) => item.id).includes(item.id))}
					/>
				</div>
				<div style={{ zIndex: 2 }}>
					<ModalNewCollab
						isMandataire={isMandataire}
						modalNewCollab={modalNewCollab}
						setModalNewCollab={setModalNewCollab}
						projectId={projectId}
						projectName={projectName}
						projectTag={projectTag}
						projectActive={projectIsActive}
						actors={actors}
						collaborators={collaborators}
						mandataireUid={mandataireUid}
					/>
				</div>
			</div>

			<div style={{ display: 'flex' }}>
				<div style={{ zIndex: 1 }}>
					<ModalClientsAccount
						isMandataire={isMandataire}
						modalClientsAccounts={modalClientsAccounts}
						setModalClientsAccounts={setModalClientsAccounts}
						setModalNewClient={setModalNewClient}
						uid={agencyUidModal}
						projectId={projectId}
						accounts={actors.filter((item) => item.type === 'clients')}
						client={true}
					/>
				</div>
				<div style={{ zIndex: 2 }}>
					<ModalNewClient
						modalNewClient={modalNewClient}
						setModalNewClient={setModalNewClient}
						clients={clients}
						actors={actors}
						projectId={projectId}
						projectName={projectName}
						projectActive={projectIsActive}
						projectTag={projectTag}
						mandataireUid={mandataireUid}
					/>
				</div>
			</div>

			<ModalPartnerCompany
				isMandataire={isMandataire}
				modalPartnerCompany={modalPartnerCompany}
				setModalPartnerCompany={setModalPartnerCompany}
				uid={agencyUidModal}
				projectId={projectId}
				accounts={actors.filter((actor) => agencyUidModal === actor.agency)}
			/>

			<ModalNewPartnerCompany
				modalNewPartnerCompany={modalNewPartnerCompany}
				setModalNewPartnerCompany={setModalNewPartnerCompany}
				projectId={projectId}
				actors={actors}
				isActive={projectIsActive}
				projectName={projectName}
				projectTag={projectTag}
				partnerAgencies={partnerAgencies}
			/>
		</div>
	);
}
