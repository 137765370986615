import React from 'react';
import styles from './LocationList.module.css';
import { useTranslation } from 'react-i18next';
import { useProjectLocations } from '../../../../../../contexts/projectLocationsContext';
import mappin from '../../../../../../assets/mappin.svg';
import mappinBlue from '../../../../../../assets/mappinBlue.svg';

export default function LocationsList({ groupedPictures, scrollToLocation, visibleIndex }) {
	const { t } = useTranslation();

	const [locations] = useProjectLocations();

	return (
		<div className={styles.locationsList}>
			{groupedPictures.map((locationGroup, index) => (
				<ItemLocation
					key={index}
					isVise={visibleIndex === index}
					onClick={() => scrollToLocation(index)}
					name={
						locations?.find((location) => location.id === locationGroup.locationId)?.name ??
						t('All site pictures')
					}
				/>
			))}
		</div>
	);
}

function ItemLocation({ onClick, isVise, name }) {
	return (
		<div
			className={isVise ? styles.itemLocationVise : styles.itemLocation}
			onClick={onClick}
			style={{ cursor: 'pointer' }}>
			<img src={isVise ? mappinBlue : mappin} alt="" className={styles.mappin} />
			<span>{name}</span>
		</div>
	);
}
