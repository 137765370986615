import O_Opus from '../assets/O_Opus.png';
import React from 'react';

export function wait(timeout) {
	return new Promise((resolve) => setTimeout(resolve, timeout));
}

export function add48Hours(dateISOString) {
	if (!dateISOString) {
		return '';
	}
	const date = new Date(dateISOString);
	date.setTime(date.getTime() + 48 * 60 * 60 * 1000);
	return date.toISOString();
}

export function changeSortString(string) {
	if (string) {
		const index = string.indexOf('*\\');
		return string.slice(index > -1 ? index + 2 : 0);
	} else {
		return string;
	}
}

export function stringTime(language, dateISOString) {
	if (!dateISOString) {
		return '';
	}

	const date = new Date(dateISOString);
	let hours = date.getHours();
	let minutes = date.getMinutes();
	hours = hours < 10 ? '0' + hours.toString() : hours;
	minutes = minutes < 10 ? '0' + minutes.toString() : minutes;
	if (language.includes('fr')) {
		return hours + ':' + minutes;
	} else {
		return hours > 0 && hours < 13 ? hours + ':' + minutes + ' am' : Math.abs(hours - 12) + ':' + minutes + ' pm';
	}
}

export function stringDate(dateISOString) {
	let day = new Date(dateISOString).getDate();
	if (day < 10) {
		day = '0' + day;
	}
	let month = new Date(dateISOString).getMonth() + 1;
	if (month < 10) {
		month = '0' + month;
	}
	let year = new Date(dateISOString).getFullYear();
	return `${day}/${month}/${year}`;
}

export function timeOrDate(dateISOString, t) {
	const date = new Date(dateISOString);
	const today = new Date(new Date().setHours(0));
	const yesterday = new Date(new Date().setHours(0));
	yesterday.setDate(yesterday.getDate() - 1);

	if (date > today) {
		return stringTime('fr', dateISOString);
	} else if (date > yesterday) {
		return t('common.yesterday');
	} else {
		return stringDate(dateISOString);
	}
}

export function dateString(t, dateISOString, longVersion, withDay, withoutYear) {
	if (!dateISOString) {
		return '';
	}

	const date = new Date(dateISOString);
	let day = date.getDay();
	let dayDate = date.getDate().toString();
	let month = date.getMonth() + 1;
	const year = date.getFullYear().toString();
	if (dayDate.length === 1) {
		dayDate = '0' + dayDate;
	}

	if (longVersion) {
		//case month
		switch (month) {
			case 1:
				month = t('long_months.january');
				break;
			case 2:
				month = t('long_months.february');
				break;
			case 3:
				month = t('long_months.march');
				break;
			case 4:
				month = t('long_months.april');
				break;
			case 5:
				month = t('long_months.may');
				break;
			case 6:
				month = t('long_months.june');
				break;
			case 7:
				month = t('long_months.july');
				break;
			case 8:
				month = t('long_months.august');
				break;
			case 9:
				month = t('long_months.september');
				break;
			case 10:
				month = t('long_months.october');
				break;
			case 11:
				month = t('long_months.november');
				break;
			case 12:
				month = t('long_months.december');
				break;
			default:
				break;
		}
		switch (day) {
			case 0:
				day = t('days_of_a_week.sunday');
				break;
			case 1:
				day = t('days_of_a_week.monday');
				break;
			case 2:
				day = t('days_of_a_week.tuesday');
				break;
			case 3:
				day = t('days_of_a_week.wednesday');
				break;
			case 4:
				day = t('days_of_a_week.thursday');
				break;
			case 5:
				day = t('days_of_a_week.friday');
				break;
			case 6:
				day = t('days_of_a_week.saturday');
				break;
			default:
				break;
		}
		return `${withDay ? day + ' ' : ''}${dayDate} ${month}${withoutYear ? '' : ' ' + year}`;
	} else {
		if (month < 10) {
			month = '0' + month;
		}
		return `${dayDate}/${month}/${year}`;
	}
}

export function dateAddTwoMonth(dateISOString) {
	return new Date(new Date(dateISOString).getTime() + 60 * 24 * 60 * 60 * 1000);
}

export function timeString(dateISOString) {
	const hours = new Date(dateISOString).getHours().toString();
	let minutes = new Date(dateISOString).getMinutes().toString();
	if (minutes.length === 1) {
		minutes = '0' + minutes;
	}
	return hours + ':' + minutes;
}

export function transformString(string) {
	if (string) {
		return string
			.toLowerCase()
			.replace(/\s+/g, '')
			.replace('-', '')
			.replace('_', '')
			.replace('/', '')
			.replace('(', '')
			.replace(')', '')
			.replace('é', 'e')
			.replace('è', 'e')
			.replace('ê', 'e')
			.replace('à', 'a')
			.replace('â', 'a')
			.replace('ù', 'u')
			.replace('û', 'u')
			.replace('ç', 'c');
	}
	return '';
}

export function normalizeString(str) {
	return str
		? (str
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '')
				.toLowerCase()
				.replace(/\s+/g, '') ?? '')
		: '';
}

export function passwordIsStrong(password) {
	return withMinuscule(password) && withMajuscule(password) && withNumber(password) && withSymbol(password);
}

export function withMinuscule(string) {
	return (
		string &&
		(string.includes('a') ||
			string.includes('b') ||
			string.includes('c') ||
			string.includes('d') ||
			string.includes('e') ||
			string.includes('f') ||
			string.includes('g') ||
			string.includes('h') ||
			string.includes('i') ||
			string.includes('j') ||
			string.includes('k') ||
			string.includes('l') ||
			string.includes('m') ||
			string.includes('n') ||
			string.includes('o') ||
			string.includes('p') ||
			string.includes('g') ||
			string.includes('r') ||
			string.includes('s') ||
			string.includes('t') ||
			string.includes('u') ||
			string.includes('v') ||
			string.includes('w') ||
			string.includes('x') ||
			string.includes('y') ||
			string.includes('z'))
	);
}

export function withMajuscule(string) {
	return (
		string &&
		(string.includes('A') ||
			string.includes('B') ||
			string.includes('C') ||
			string.includes('D') ||
			string.includes('E') ||
			string.includes('F') ||
			string.includes('G') ||
			string.includes('H') ||
			string.includes('I') ||
			string.includes('J') ||
			string.includes('K') ||
			string.includes('L') ||
			string.includes('M') ||
			string.includes('N') ||
			string.includes('O') ||
			string.includes('P') ||
			string.includes('G') ||
			string.includes('R') ||
			string.includes('S') ||
			string.includes('T') ||
			string.includes('U') ||
			string.includes('V') ||
			string.includes('W') ||
			string.includes('X') ||
			string.includes('Y') ||
			string.includes('Z'))
	);
}

export function withNumber(string) {
	return (
		string &&
		(string.includes('1') ||
			string.includes('2') ||
			string.includes('3') ||
			string.includes('4') ||
			string.includes('5') ||
			string.includes('6') ||
			string.includes('7') ||
			string.includes('8') ||
			string.includes('9') ||
			string.includes('0'))
	);
}

export function withSymbol(string) {
	return (
		string &&
		(string.includes('@') ||
			string.includes('&') ||
			string.includes('(') ||
			string.includes(')') ||
			string.includes('!') ||
			string.includes('-') ||
			string.includes('_') ||
			string.includes('*') ||
			string.includes('$') ||
			string.includes('€') ||
			string.includes('|') ||
			string.includes('#') ||
			string.includes('^') ||
			string.includes('%') ||
			string.includes('£') ||
			string.includes('+') ||
			string.includes('=') ||
			string.includes('/') ||
			string.includes(':') ||
			string.includes(';') ||
			string.includes('.') ||
			string.includes(',') ||
			string.includes('?') ||
			string.includes('§') ||
			string.includes('<') ||
			string.includes('>') ||
			string.includes('"') ||
			string.includes("'") ||
			string.includes('°') ||
			string.includes('¨') ||
			string.includes('`') ||
			string.includes('æ') ||
			string.includes('≠') ||
			string.includes('÷') ||
			string.includes('…') ||
			string.includes('∞') ||
			string.includes('}') ||
			string.includes('{') ||
			string.includes('[') ||
			string.includes(']'))
	);
}

export function addSpaceToNumber(nStr) {
	if (nStr === null || nStr === undefined) {
		return '';
	}

	nStr = String(nStr);

	const isNegative = nStr.startsWith('-');
	if (isNegative) {
		nStr = nStr.substring(1);
	}

	const x = nStr.split('.');
	let x1 = x[0];
	const x2 = x.length > 1 ? '.' + x[1] : '';
	const rgx = /(\d+)(\d{3})/;
	while (rgx.test(x1)) {
		x1 = x1.replace(rgx, '$1' + ' ' + '$2');
	}

	return (isNegative ? '-' : '') + x1 + x2;
}

export const Language = navigator.language.slice(0, 2);

export function getPlanStringPlan(plan) {
	switch (plan) {
		case 'essential':
			return 'Essentiel';
		case 'professional':
			return 'Professionnel';
		case 'premium':
			return 'Premium';
		default:
			return plan;
	}
}

export function stringSubtype(subtype, t) {
	return subtype === 'administrator'
		? t('common.admin')
		: subtype === 'collaborator'
			? t('common.collab').charAt(0).toUpperCase() + t('common.collab').slice(1)
			: t('common.personalized');
}

export default function profilPicture(imgUrl) {
	return imgUrl && !imgUrl.includes('O_Opus') ? imgUrl : O_Opus;
}

export function adjustText(text, maxLength) {
	if (text) {
		if (text.length <= maxLength) {
			return <p className={'name'}>{text}</p>;
		}

		const sideLength = Math.floor(maxLength / 2) - 2;

		const startText = text.substr(0, sideLength);
		const endText = text.substr(-sideLength);

		return <p className={'name'}>{startText + ' [...] ' + endText}</p>;
	}
	return '';
}

export function getFirstLetters(str) {
	return str
		? str
				.split(' ')
				.map((word) => word[0])
				.join('')
		: '';
}

export function desescapeSpecialChars(str) {
	const escapeChars = {
		'&amp;': '&',
		'&lt;': '<',
		'&gt;': '>',
		'&quot;': '"',
		'&#39;': "'",
		'&#x2F;': '/',
		'&#x60;': '`',
		'&#x3D;': '=',
	};

	return String(str).replace(/&amp;|&lt;|&gt;|&quot;|&#39;|&#x2F;|&#x60;|&#x3D;/g, function (match) {
		return escapeChars[match];
	});
}

export const removeDuplicates = (array, key) => {
	const unique = array.reduce((acc, current) => {
		const x = acc.find((item) => item[key] === current[key]);
		if (!x) {
			return acc.concat([current]);
		} else {
			return acc;
		}
	}, []);
	return unique;
};
