import React from 'react';
import './_photoVideo.scss';
import ReactPlayer from 'react-player';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

export default function PhotoVideo({ selectedPhoto, bigScreen, transformWrapperRef }) {
	return (
		<div
			style={{ width: bigScreen ? '100vw' : 'calc(100vw - 400px)' }}
			className={'containerPhotoVideo transition'}>
			{selectedPhoto &&
				(selectedPhoto.type === 'video' ? (
					<div
						className={'containerVideo transition'}
						style={{
							width: bigScreen ? '100vw' : 'calc(100vw - 400px)',
						}}>
						<ReactPlayer
							className="react-player"
							url={selectedPhoto.url}
							controls
							width="100%"
							height={'calc(100vh - 200px)'}
						/>
					</div>
				) : (
					<TransformWrapper ref={transformWrapperRef}>
						<TransformComponent>
							<div
								className={'containerPhoto transition'}
								style={{
									width: bigScreen ? '100vw' : 'calc(100vw - 400px)',
								}}>
								<img
									src={selectedPhoto.url}
									className={'photoOuvert transition'}
									style={{
										maxWidth: bigScreen ? 'calc(100vw - 200px)' : 'calc(100vw - 500px)',
									}}
									alt={''}
								/>
							</div>
						</TransformComponent>
					</TransformWrapper>
				))}
		</div>
	);
}
