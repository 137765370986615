import React, { useContext, useEffect, useState } from 'react';
import AgencyContext from '../../../contexts/agencyContext';
import { transformString } from '../../../useful/UsefulFunctions';
import { deleteUserInProject } from '../../../useful/UsefulForProject';
import { useTranslation } from 'react-i18next';
import { auth, firestore, functions } from '../../../firebase/config';
import { httpsCallable } from 'firebase/functions';
import { createdByConstructor } from '../../../pages/Fiche Projet/utils';
import AgencyDataContext from '../../../contexts/agencyDataContext';
import { cleanNaNValues } from 'src/useful/utils';

export default function CollaboratorItemListActors({
	uid,
	projectId,
	actors,
	research,
	isActive,
	setResearchNumber,
	projectName,
	projectTag,
	mandataireUid,
	agencyAccessMessaging,
}) {
	const { t } = useTranslation();

	const agency = useContext(AgencyContext);
	const authId = auth.currentUser?.uid;
	const agencyData = useContext(AgencyDataContext);

	const [isLoading, setIsLoading] = useState(false);
	const [name, setName] = useState();
	const [surname, setSurname] = useState();
	const [userData, setUserData] = useState();
	const [oldResearch, setOldResearch] = useState(false);

	const [discussionPriveeClient, setDiscussionPriveeClient] = useState(false);
	const [loadingMessaging, setLoadingMessaging] = useState(false);

	useEffect(() => {
		firestore
			.doc(`agencies/${agency}/collaborators/${uid}`)
			.get()
			.then((documentSnapshot) => {
				if (documentSnapshot && documentSnapshot.exists) {
					setName(documentSnapshot.data().name);
					setSurname(documentSnapshot.data().surname);
					setUserData({ ...documentSnapshot.data(), id: documentSnapshot.id });
				}
			});
		firestore
			.doc(`agencies/${agency}/collaborators/${uid}/projects/${projectId}`)
			.get()
			.then((documentSnapshot) => {
				if (documentSnapshot && documentSnapshot.exists) {
					setDiscussionPriveeClient(documentSnapshot.data().accessMessaging);
				}
			});
	}, [uid, agency, projectId]);

	useEffect(() => {
		if (name) {
			if (transformString(name).includes(transformString(research)) && !oldResearch) {
				setResearchNumber((prevValue) => prevValue + 1);
				setOldResearch(true);
			} else if (!transformString(name).includes(transformString(research)) && oldResearch) {
				setResearchNumber((prevValue) => prevValue - 1);
				setOldResearch(false);
			}
		}
	}, [research, name]);

	return (
		<>
			{transformString(surname + name).includes(transformString(research)) && (
				<div className={actors && actors.includes(uid) && agencyAccessMessaging ? 'isSelected' : null}>
					<div
						className={'infos'}
						style={{ marginLeft: actors && actors.includes(uid) && agencyAccessMessaging ? null : 15 }}>
						<div
							style={{
								marginBottom: '17px',
								marginTop: 17,
								width: '51px',
								height: '51px',
								borderRadius: '51px',
								marginRight: '14.45px',
								objectFit: 'cover',
								marginLeft: '10px',
								backgroundColor: '#7FA3FC',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								color: '#FFFFFF',
								fontWeight: '600',
								fontSize: '19px',
							}}>
							<p
								style={{
									letterSpacing: 1,
									fontSize: 19,
									margin: 0,
								}}>
								{surname?.charAt(0)?.toUpperCase()}
								{name?.charAt(0)?.toUpperCase()}
							</p>
						</div>

						<p className={'name'} style={{ fontWeight: 600 }}>{`${surname} ${name}`}</p>
					</div>

					<div style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
						<div className={'barActeur'} />

						{uid !== authId || mandataireUid !== agency ? (
							actors && actors.includes(uid) ? (
								<button
									className={'supprimerActeur'}
									onClick={async () => {
										if (
											window.confirm(
												`${t('collab_list_item.sure_to_remove_collab', { name: name })}`
											)
										) {
											setIsLoading(true);
											await deleteUserInProject(uid, 'collaborators', agency, projectId, agency);
											setIsLoading(false);
										}
									}}
									disabled={isLoading}>
									<p>{t('common.delete')}</p>
								</button>
							) : (
								<button
									className={'ajouterActeur'}
									style={{ marginLeft: -12 }}
									onClick={async () => {
										setIsLoading(true);
										const call = httpsCallable(functions, 'addUserToProject');
										call(
											cleanNaNValues({
												createdBy: createdByConstructor(uid, 'collaborator', agency),
												projectId,
												parent: agencyData,
												data: userData,
												senderName: surname + ' ' + name,
												projectName,
												projectImgUrl: '',
												withoutNotification: true,
											})
										).then((r) => {
											setIsLoading(false);
										});
									}}
									disabled={isLoading}>
									<p>{t('common.add')}</p>
								</button>
							)
						) : null}

						{uid === authId && mandataireUid === agency && (
							<p
								className={'ajouterActeur'}
								style={{
									marginLeft: 378,
									marginRight: 50,
									backgroundColor: 'rgba(239,160,106,0)',
									color: '#146146',
									fontSize: 13,
									cursor: 'default',
								}}>
								{t('common.you')}
							</p>
						)}
					</div>
				</div>
			)}
		</>
	);
}
