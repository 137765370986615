import React from 'react';
import placeholderPhotoAlbums from 'src/assets/placeholderPhotoAlbums.png';

export default function PlaceholderAlbum({ opacity2, t, margin1, margin2, newPhoto, isModeLecture }) {
	return (
		<div
			style={{
				opacity: opacity2,
			}}
			className={'placeholderAlbums'}>
			<div>
				<img src={placeholderPhotoAlbums} alt="" className={'placeholderPhotoAlbums'} />
				<div style={{ marginLeft: 40 }}>
					<p style={{ marginLeft: margin1 }} className={'titlePlaceHolder'}>
						{t('photos.add_photos_to_albums')}
					</p>
					<p style={{ marginLeft: margin1 }} className={'subtitlePlaceHolder'}>
						{t('photos.permit_your_partner_a_overview')}
					</p>
					<p style={{ marginLeft: margin1 }} className={'subtitlePlaceHolder'}>
						{t('photos.add_thematic_pics')}
					</p>

					{!isModeLecture && (
						<label htmlFor={'input'}>
							<div style={{ marginLeft: margin2 }} className={'button'}>
								<p className={'textButton'}>{t('photos.import_pictures')}</p>
							</div>
						</label>
					)}

					<input
						type="file"
						id="inputNoPhoto"
						accept="image/*,video/*,.heic,.heif"
						multiple={true}
						onChange={newPhoto}
					/>
				</div>
			</div>
		</div>
	);
}
