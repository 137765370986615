import React, { useContext, useEffect, useRef, useState } from 'react';
import './_threeDotsMenu.scss';
import { useTranslation } from 'react-i18next';
import { usePartnersContext } from 'src/contexts/partnersContext';
import { useProjectContext } from 'src/contexts/projectContext';
import { auth, firestore } from 'src/firebase/config';
import { useClientsContext } from 'src/contexts/clientsContext';
import TypeContext from 'src/contexts/typeContext';
import AgencyContext from 'src/contexts/agencyContext';
import AuthDataContext from 'src/contexts/authDataContext';
import ModalTicket from 'src/pages/Fiche Projet/Tickets/components/Modal Ticket/ModalTicket';
import { generateUniqueFirestoreId, joinPaths, uploadOnStorage } from 'src/firebase/utils';
import mixpanel from 'mixpanel-browser';
import { createdByConstructor } from 'src/pages/Fiche Projet/utils';
import { TICKET_STATUS } from 'src/pages/Fiche Projet/Tickets/components/Modal Ticket/Follow Up/FollowUp';
import { Paths } from 'src/firebase/paths';
import { TICKET_LOG_TYPE } from 'src/pages/Fiche Projet/Tickets/data';
import { resizeImage } from 'src/useful/image';
import { increment } from 'firebase/firestore';

export default function ThreeDotsMenu({
	downloadPhotos,
	selectedPhoto,
	saveAsProjectCover,
	loadingProjectCover,
	enregistreeProjectCover,
	saveAsAlbumCover,
	loadingCouvertureAlbum,
	enregistree,
	deletePicture,
	albumSelected,
	isAgencyMatch,
	isModeLecture,
	bigScreen,
	handleModifyLots,
	handleModifyLocations,
}) {
	const { t } = useTranslation();

	const uid = auth.currentUser?.uid;
	const userType = useContext(TypeContext);
	const authData = useContext(AuthDataContext);
	const agencyId = useContext(AgencyContext);
	const [project] = useProjectContext();
	const [clients] = useClientsContext();

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [loadingCreateSnag, setLoadingCreateSnag] = useState(false);
	const [modalTicket, setModalTicket] = useState(false);
	const [newTicketData, setNewTicketData] = useState(null);

	const refMenu = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (refMenu.current && !refMenu.current.contains(event.target)) {
				setIsMenuOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [refMenu]);

	const showMenu = () => {
		setIsMenuOpen(true);
	};

	const createSnag = async () => {
		if (!loadingCreateSnag) {
			setLoadingCreateSnag(true);

			try {
				mixpanel.track('Work Created', {
					'Project ID': project.id,
					Page: 'workspace',
					'Work Type': 'snag',
				});

				const id = generateUniqueFirestoreId();
				const fileId = generateUniqueFirestoreId();

				// Prepare ticket data
				const data = {
					id,
					status: 'ongoing',
					name: t('translation.newSnag'),
					properties: {
						visibility: 'collaborative',
						type: 'issue',
						owner: createdByConstructor(uid, userType, agencyId),
						issueAt: new Date().toISOString(),
						reviewers:
							userType === 'clients' || clients?.map((item) => item.id)?.includes(agencyId)
								? ['clients']
								: [agencyId],
						users: [],
					},
					createdBy: createdByConstructor(uid, userType, agencyId),
					createdAt: new Date().toISOString(),
					modifiedAt: new Date().toISOString(),
					companiesViewed:
						userType === 'clients'
							? ['clients', uid]
							: clients?.map((item) => item.id)?.includes(agencyId)
								? ['clients', agencyId, uid]
								: [agencyId, uid],
					issueStatus: TICKET_STATUS.inProgress,
					missionStatus: 'ongoing',
				};

				await firestore.runTransaction(async (transaction) => {
					const countersRef = firestore.doc(joinPaths(Paths.COUNTERS, project.id));
					const counters = await transaction.get(countersRef);

					if (!counters.exists) {
						transaction.set(countersRef, {
							snags: 1,
							missions: 1,
						});
					} else {
						transaction.update(countersRef, {
							snags: increment(1),
							missions: increment(1),
						});
					}

					transaction.set(
						firestore.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS)).doc(id),
						{
							...data,
							index: (counters.data()?.snags ?? 0) + 1,
						}
					);
				});

				const batch = firestore.batch();

				// Add description to batch
				const contentRef = firestore
					.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, id, Paths.CONTENT))
					.doc(id);
				batch.set(contentRef, {
					type: 'text',
					title: t('translation.description'),
					text: selectedPhoto?.comments?.length > 0 ? selectedPhoto.comments[0].content : '',
					createdBy: createdByConstructor(uid, userType, agencyId),
					createdAt: new Date().toISOString(),
					modifiedAt: new Date().toISOString(),
				});

				// Add log to batch
				const logRef = firestore
					.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, id, Paths.LOGS))
					.doc();
				batch.set(logRef, {
					type: TICKET_LOG_TYPE.created,
					name: authData.surname + ' ' + authData.name,
					createdAt: new Date().toISOString(),
					createdBy: createdByConstructor(uid, userType, agencyId),
				});

				// Handle file upload separately after batch success
				if (selectedPhoto) {
					const response = await fetch(selectedPhoto.url);
					const blob = await response.blob();
					const file = new File([blob], selectedPhoto.name || `image${fileId}.jpg`, { type: blob.type });
					const processedFile = await resizeImage(file);

					const fileData = {
						id: fileId,
						name: file.name,
						type: file.type.includes('image') ? 'image' : 'file',
						size: processedFile.size,
						mimeType: processedFile.type,
						createdAt: new Date().toISOString(),
						createdBy: createdByConstructor(uid, userType, agencyId),
						modifiedAt: new Date().toISOString(),
					};

					const downloadURL = await uploadOnStorage(
						fileId,
						processedFile,
						{ ...fileData, page: 'tickets' },
						`projects/${project.id}/tickets/${id}/files`
					);

					batch.set(
						firestore
							.collection(joinPaths(Paths.PROJECTS, project.id, Paths.TICKETS, id, Paths.CONTENT))
							.doc(fileId),
						{
							...fileData,
							url: downloadURL,
						}
					);
				}

				// Commit batch
				await batch.commit();

				setNewTicketData(data);
				setModalTicket(true);
			} catch (error) {
				console.error('Error creating snag:', error);
				alert(t('translation.error_occurred'));
			} finally {
				setLoadingCreateSnag(false);
			}
		}
	};

	return (
		<div>
			{!isModeLecture && (
				<div onClick={showMenu} className={'threeDots hover'}>
					<div className={'dot'} style={{ backgroundColor: bigScreen && '#FFF' }} />
					<div className={'dot'} style={{ backgroundColor: bigScreen && '#FFF' }} />
					<div className={'dot'} style={{ backgroundColor: bigScreen && '#FFF' }} />
				</div>
			)}

			<div className={`menuBigPhoto ${isMenuOpen ? 'show' : ''}`} ref={refMenu}>
				<div
					onClick={() => {
						downloadPhotos([selectedPhoto]);
					}}
					className={'buttonMenuBigPhoto'}>
					{t('photos.download_image')}
				</div>

				{/* Define as Cover Button */}
				<div
					style={{
						color: enregistreeProjectCover ? '#569F59' : null,
					}}
					onClick={saveAsProjectCover}
					className={'buttonMenuBigPhoto'}>
					{loadingProjectCover
						? t('photos.addition_in_progression')
						: enregistreeProjectCover
							? t('photos.saved')
							: t('photos.define_as_cover')}
				</div>

				{/* Add to Cover Button */}
				{albumSelected?.type !== 'visit' && (
					<div
						style={{
							color: enregistree ? '#569F59' : null,
							opacity: isAgencyMatch(albumSelected?.createdBy?.agencyId) ? 1 : 0.4,
						}}
						onClick={saveAsAlbumCover}
						className={'buttonMenuBigPhoto'}>
						{loadingCouvertureAlbum
							? t('photos.addition_in_progression')
							: enregistree
								? t('photos.saved')
								: t('photos.add_to_cover')}
						{albumSelected && !isAgencyMatch(albumSelected?.createdBy?.agencyId) && (
							<AgencyTag agency={albumSelected?.createdBy?.agencyId} />
						)}
					</div>
				)}

				<div onClick={createSnag} className={'buttonMenuBigPhoto'}>
					{loadingCreateSnag ? t('translation.snag_creation_in_progress') : t('translation.create_snag')}
				</div>

				{/* Delete Picture Button */}
				{albumSelected && (
					<div
						onClick={deletePicture}
						className={'buttonMenuBigPhoto'}
						style={{
							color: '#d94040',
							opacity: isAgencyMatch(selectedPhoto?.createdBy?.agencyId) ? 1 : 0.4,
						}}>
						{t('photos.delete_pic')}
						{selectedPhoto && !isAgencyMatch(selectedPhoto?.createdBy?.agencyId) && (
							<AgencyTag agency={selectedPhoto?.createdBy?.agencyId} />
						)}
					</div>
				)}
			</div>

			<ModalTicket
				isOpen={modalTicket}
				data={newTicketData}
				onClose={() => {
					setModalTicket(false);
					setTimeout(() => {
						setNewTicketData(null);
					}, 100);
				}}
				isSnagging={true}
				darkerOverlay={true}
				handleModifyLots={handleModifyLots}
				handleModifyLocations={handleModifyLocations}
			/>
		</div>
	);
}

function AgencyTag({ agency }) {
	const { partners } = usePartnersContext();
	const { t } = useTranslation();
	return (
		<div style={{ display: 'flex' }}>
			<div
				style={{
					borderRadius: 13,
					marginTop: 3,
					height: 18,
					marginLeft: 0,
					color: '#3f5cf6',
					fontSize: 13,
					fontWeight: '700',
					padding: '3px 8px',
					letterSpacing: 0,
				}}>
				{agency === 'clients'
					? t('translation.client')
					: partners?.find((partner) => partner.id === agency)?.diminutifAgency}
			</div>
		</div>
	);
}
