import React, { useEffect, useRef, useState } from 'react';
import './_annotationPart.scss';
import bigEditIcon from '../../../../../../../assets/bigEditIcon.svg';
import { auth, firestore } from '../../../../../../../firebase/config';
import { useUsersContext } from '../../../../../../../contexts/usersContext';
import {
	AGENCY_COLLECTION,
	CLIENT_COLLECTION,
	COLLABORATOR_COLLECTION,
	MEDIA_COLLECTION,
	PROJECT_COLLECTION,
	USER_COLLECTION,
} from '../../../../../../../firebase/paths';
import { useProjectContext } from '../../../../../../../contexts/projectContext';
import { useTranslation } from 'react-i18next';

export default function AnnotationsPart({ selectedPhoto, setSelectedPhoto, setPhotos }) {
	const { t } = useTranslation();

	const uid = auth.currentUser?.uid;
	const [project] = useProjectContext();

	const [isAnnotationMode, setIsAnnotationMode] = useState(false);
	const [newAnnotationText, setNewAnnotationText] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const handlePublishAnnotation = async (text) => {
		if (!isLoading) {
			setIsLoading(true);
			const newComments =
				(
					await firestore
						.doc(`${PROJECT_COLLECTION}/${project.id}/${MEDIA_COLLECTION}/${selectedPhoto.id}`)
						.get()
				).data().comments ?? [];
			const index = newComments.findIndex((comment) => comment.userId === uid);
			if (index === -1) {
				if (text.length > 0) {
					newComments.push({ userId: uid, content: text, createdAt: new Date().toISOString() });
				}
			} else {
				if (text.length > 0) {
					newComments[index] = { ...newComments[index], content: text };
				} else {
					newComments.splice(index, 1);
				}
			}
			setSelectedPhoto({ ...selectedPhoto, comments: newComments });
			setPhotos((photos) => {
				const newPhotos = [...photos];
				newPhotos[newPhotos.findIndex((photo) => photo.id === selectedPhoto.id)] = {
					...selectedPhoto,
					comments: newComments,
				};
				return newPhotos;
			});
			setIsAnnotationMode(false);
			await firestore.doc(`${PROJECT_COLLECTION}/${project.id}/${MEDIA_COLLECTION}/${selectedPhoto.id}`).update({
				comments: newComments,
			});
			setIsLoading(false);
		}
	};

	return (
		<div className={'containerAnnotation'}>
			<div className={'listAnnotations'}>
				{selectedPhoto?.comments?.length > 0 ? (
					<>
						{selectedPhoto?.comments?.map((comment) => (
							<AnnotationItem
								key={comment.userId}
								userId={comment.userId}
								content={comment?.content}
								handlePublishAnnotation={handlePublishAnnotation}
							/>
						))}
					</>
				) : (
					!isAnnotationMode && (
						<div className={'placeholderAnnotations'}>
							{t('translation.noAnnotation')} <br /> {t('translation.noAnnotation2')}
						</div>
					)
				)}

				{isAnnotationMode && (
					<AnnotationItem
						key={'new'}
						userId={uid}
						content={''}
						handlePublishAnnotation={() => null}
						setNewAnnotationText={setNewAnnotationText}
					/>
				)}

				{!selectedPhoto?.comments?.some((item) => item.userId === uid) &&
					(isAnnotationMode ? (
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<button
								className="publishAnnotation"
								onClick={() => {
									handlePublishAnnotation(newAnnotationText.trim());
									setNewAnnotationText('');
								}}>
								{t('translation.publishAnnotation')}
							</button>
						</div>
					) : (
						<div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
							<button className="editAnnotation" onClick={() => setIsAnnotationMode(true)}>
								{t('translation.addPersonalAnnotations')}
							</button>
						</div>
					))}
			</div>
		</div>
	);
}

function AnnotationItem({ userId, content, handlePublishAnnotation, setNewAnnotationText }) {
	const { t } = useTranslation();

	const uid = auth.currentUser.uid;
	const [users, setUsers] = useUsersContext();

	const inputLegend = useRef();

	const [name, setName] = useState('');
	const [text, setText] = useState(content);

	// ==================================== Resize input title ====================================

	useEffect(() => {
		const adjustHeight = () => {
			const textarea = inputLegend.current;
			if (textarea) {
				textarea.style.height = 'auto';
				textarea.style.height = `${textarea.scrollHeight}px`;
			}
		};

		adjustHeight();
		window.addEventListener('resize', adjustHeight);

		return () => window.removeEventListener('resize', adjustHeight);
	}, [text]);

	useEffect(() => {
		if (userId) {
			if (!users.find((user) => user.id === userId)) {
				firestore
					.collection(USER_COLLECTION)
					.doc(userId)
					.get()
					.then((doc) => {
						if (doc.exists) {
							if (doc.data().type === 'collaborators') {
								firestore
									.collection(AGENCY_COLLECTION)
									.doc(doc.data().agency)
									.collection(COLLABORATOR_COLLECTION)
									.doc(userId)
									.get()
									.then((doc1) => {
										if (doc1.exists) {
											setUsers((users) => [
												...users,
												{ id: userId, ...doc.data(), ...doc1.data() },
											]);
											setName(doc1.data().surname + ' ' + doc1.data().name);
										}
									});
							} else {
								firestore
									.collection(CLIENT_COLLECTION)
									.doc(userId)
									.get()
									.then((doc1) => {
										if (doc1.exists) {
											setUsers((users) => [
												...users,
												{ id: userId, ...doc.data(), ...doc1.data() },
											]);
											setName(doc1.data().surname + ' ' + doc1.data().name);
										}
									});
							}
						}
					});
			} else {
				setName(
					users.find((user) => user.id === userId)?.surname +
						' ' +
						users.find((user) => user.id === userId)?.name || ''
				);
			}
		}
	}, [userId]);

	return (
		<div className={'annotationItem'} style={{ pointerEvents: userId !== uid ? 'none' : 'auto' }}>
			<p className={'name'}>{name}</p>
			<textarea
				ref={inputLegend}
				name=""
				id=""
				className={'textarea'}
				placeholder={t('Write your annotation here...')}
				value={text}
				onBlur={(e) => {
					if (e.target.value.trim() !== content) {
						handlePublishAnnotation(e.target.value.trim());
					}
				}}
				onChange={(e) => {
					if (setNewAnnotationText) {
						setNewAnnotationText(e.target.value);
					}
					setText(e.target.value);
					e.target.style.height = 'auto';
					e.target.style.height = `${e.target.scrollHeight}px`;
				}}
			/>
		</div>
	);
}
