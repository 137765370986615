import React, { useMemo } from 'react';
import './_zoomAndNavControls.scss';
import leftIcon from '../../../../../../assets/leftIcon.png';
import zoomOutIcon from '../../../../../../assets/zoomOut.svg';
import zoomOriginal from '../../../../../../assets/zoomOriginal.svg';
import zoomInIcon from '../../../../../../assets/zoomIn.svg';

export default function ZoomAndNavControls({
	selectedPhoto,
	setSelectedPhoto,
	photos,
	zoomIn,
	zoomOut,
	resetTransform,
	bigScreen,
}) {
	const index = useMemo(() => photos?.findIndex((photo) => photo.id === selectedPhoto?.id), [photos, selectedPhoto]);

	return (
		<div
			className={'containerZoomNav transition'}
			style={{
				position: selectedPhoto && selectedPhoto.type === 'video' && 'relative',
				width: bigScreen ? '100vw' : 'calc(100vw - 400px)',
			}}>
			<div
				className={'previousNext'}
				style={{ opacity: index === 0 ? 0.5 : 1 }}
				onClick={() => {
					if (index !== -1) {
						setSelectedPhoto(photos[index - 1]);
					}
				}}>
				<img src={leftIcon} alt="" className={'leftIcon'} />
			</div>

			{selectedPhoto && selectedPhoto.type !== 'video' && (
				<div className={'zoomInOut'}>
					<div className={'buttonZoom'} onClick={zoomOut}>
						<img src={zoomOutIcon} alt="" className={'zoomOutIcon'} />
					</div>
					<div className={'buttonZoom'} onClick={resetTransform}>
						<img src={zoomOriginal} alt="" className={'zoomOutIcon'} />
					</div>
					<div className={'buttonZoom'} onClick={zoomIn}>
						<img src={zoomInIcon} alt="" className={'zoomOutIcon'} />
					</div>
				</div>
			)}

			<div
				className={'previousNext'}
				onClick={() => {
					if (index !== -1) {
						setSelectedPhoto(photos[index + 1]);
					}
				}}>
				<img src={leftIcon} alt="" className={'rightIcon'} />
			</div>
		</div>
	);
}
