import React, { Fragment, useContext, useRef } from 'react';
import Modal from 'react-modal/lib/components/Modal';
import printer from 'src/assets/printer.svg';
import crossIcon from 'src/assets/crossIcon.png';
import './_modalPrintAlbumReport.scss';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { useProjectContext } from 'src/contexts/projectContext';
import PrintPagePhotos from './Print Page Photos/PrintPagePhotos';
import AgencyDataContext from 'src/contexts/agencyDataContext';
import CompanyOrPersonItem from 'src/pages/Fiche Projet/Tickets/components/Little Components/Company Or Person Item/CompanyOrPersonItem';
import { formatDateString } from 'src/useful/date';
import mappinBlue from 'src/assets/mappinBlue.svg';
import { useProjectLocations } from 'src/contexts/projectLocationsContext';

const shouldStartNewPage = (picture) => {
	const comment = picture.comments?.find((comment) => comment.userId === picture.createdBy?.userId)?.content;
	const hasLongComment = comment && comment.length > 500;

	const image = new Image();
	image.src = picture.url;

	const imageTooBig = comment && comment.length > 250 ? image.height > image.width : image.height > image.width * 1.5;

	return hasLongComment || imageTooBig;
};

const smartChunkPictures = (pictures) => {
	const chunks = [];
	let currentChunk = [];

	pictures.forEach((picture) => {
		// If the picture should start a new page and we have existing pictures,
		// start a new chunk
		if (shouldStartNewPage(picture) && currentChunk.length > 1) {
			chunks.push(currentChunk);
			currentChunk = [picture];
		}
		// If we already have 4 pictures or adding this one would make 2 pictures
		// after a vertical/long-comment picture, start a new chunk
		else if (currentChunk.length >= 4 || (currentChunk.length > 1 && currentChunk.some(shouldStartNewPage))) {
			chunks.push(currentChunk);
			currentChunk = [picture];
		} else {
			currentChunk.push(picture);
		}
	});

	// Don't forget the last chunk
	if (currentChunk.length > 0) {
		chunks.push(currentChunk);
	}

	return chunks;
};

export default function PrintAlbumReport({ isOpen, onClose, picturesByLevel, visit }) {
	const { t, i18n } = useTranslation();

	const [project] = useProjectContext();
	const agencyData = useContext(AgencyDataContext);
	const [locations] = useProjectLocations();

	const printRef = useRef();

	const handlePrint = useReactToPrint({
		contentRef: printRef,
		documentTitle: `${project.name}-${new Date().toLocaleDateString(i18n.language, {
			month: 'numeric',
			day: 'numeric',
			year: 'numeric',
		})}`,
	});

	const handlePrintClick = () => {
		handlePrint();
	};

	return (
		<Modal isOpen={isOpen} closeTimeoutMS={300} className={'page'}>
			<div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 25 }}>
				<div style={{ display: 'flex', justifyContent: 'space-between', width: '210mm', alignItems: 'center' }}>
					<div style={{ width: 25 }} />
					<span style={{ height: 50 }} onClick={handlePrintClick} className={'buttonPrint hover'}>
						<img src={printer} alt="" /> {t('translation.printReport')}
					</span>
					<img src={crossIcon} alt="" className={'crossIcon hover'} onClick={onClose} />
				</div>

				<div className={'largeBar'} />
			</div>

			<div className={'containerPage'} ref={printRef}>
				<div className={'pdfPage'}>
					<img src={project.imgUrl} alt="" className={'projectPicture'} />

					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							marginTop: 15,
							alignItems: 'center',
						}}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								marginTop: 20,
								marginBottom: 20,
							}}>
							<span className={'titleReport'}>{t('translation.onSiteVisit')}</span>

							<span className={'reportDate'}>
								{' '}
								{new Date(visit?.orderDate).toLocaleDateString(i18n.language, {
									weekday: 'short',
									month: 'short',
									day: 'numeric',
									year: 'numeric',
								})}
							</span>
							<span className={'reportDate'}>
								{t('translation.items')}{' '}
								{picturesByLevel?.flatMap((locationGroup) => locationGroup.allPictures)?.length}
							</span>
						</div>

						<div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
							<img src={agencyData?.logoUrl} alt="" className={'agencyLogo'} />
						</div>
					</div>

					<div className={'creator'}>
						{t('translation.createdBy')}{' '}
						<CompanyOrPersonItem userId={visit?.createdBy?.userId} unClickable={true} maxWidth={300} />
					</div>

					<div className={'largeBar'} />

					{visit?.description?.length > 0 && (
						<div className="reportContainer">
							<section className="report-section">
								<h3 className="section-title">{t('translation.generalDescription')}</h3>
								<p className="description">{visit?.description}</p>
							</section>
						</div>
					)}
				</div>

				{picturesByLevel?.map((locationGroup, index) => (
					<Fragment key={'locationGroup' + index}>
						<div className={'pdfPage'} key={locationGroup.locationId}>
							<div
								className={'statusBar'}
								style={{ backgroundColor: 'rgba(28, 87, 221, 0.1)', color: '#1C57DD' }}>
								<span style={{ width: 300, display: 'flex', alignItems: 'center' }}>
									<img src={mappinBlue} alt="" className={'mappinBlue'} />{' '}
									{locations?.find((location) => location.id === locationGroup.locationId)?.name ??
										t('All site pictures')}
								</span>
								<span>{formatDateString(visit?.orderDate, i18n.language)}</span>
								<span style={{ width: 300, display: 'flex', justifyContent: 'flex-end' }}>
									{project.name}
								</span>
							</div>

							<div className={'levelPage'}>
								<h2>
									{locations?.find((location) => location.id === locationGroup.locationId)?.name ??
										t('All site pictures')}
								</h2>
								<div className={'separator'} />
								<p>{visit?.levelDescriptions?.[locationGroup.locationId]}</p>
							</div>
						</div>
						{smartChunkPictures(locationGroup.allPictures).map((chunk, index) => (
							<div className={'pdfPage'} key={'chunk' + index}>
								<PrintPagePhotos
									pictures={chunk}
									visitDate={visit?.orderDate}
									locationId={locationGroup.locationId}
								/>
							</div>
						))}
					</Fragment>
				))}
			</div>
		</Modal>
	);
}
