import React from 'react';
import { useTranslation } from 'react-i18next';

const MonthYearPicker = ({ date, setDate, day, setDay }) => {
	const { t } = useTranslation();

	const days = [];
	for (let i = 1; i <= 31; i++) {
		days.push({ value: i, name: i.toString() });
	}

	const months = [
		{ value: '01', name: t('long_months.january') },
		{ value: '02', name: t('long_months.february') },
		{ value: '03', name: t('long_months.march') },
		{ value: '04', name: t('long_months.april') },
		{ value: '05', name: t('long_months.may') },
		{ value: '06', name: t('long_months.june') },
		{ value: '07', name: t('long_months.july') },
		{ value: '08', name: t('long_months.august') },
		{ value: '09', name: t('long_months.september') },
		{ value: '10', name: t('long_months.october') },
		{ value: '11', name: t('long_months.november') },
		{ value: '12', name: t('long_months.december') },
	];

	let years = [];
	for (let i = 2000; i <= new Date().getFullYear() + 100; i++) {
		years.push(i);
	}

	const selectedDay = day ? day.toString() : 'null';
	const selectedMonth = date ? (date.getMonth() < 9 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`) : 'null';
	const selectedYear = date ? date.getFullYear() : 'null';

	const handleDayChange = (e) => {
		const newDay = e.target.value;
		if (newDay !== 'null') {
			setDay(newDay);
		} else {
			setDay(null);
		}
	};

	const handleMonthChange = (e) => {
		const newMonth = e.target.value;
		if (newMonth !== 'null') {
			const newDate = new Date(
				date && !isNaN(selectedYear) ? `${selectedYear}-${newMonth}-01` : `1970-${newMonth}-01`
			);
			setDate(newDate);
		} else {
			setDate(null);
		}
	};

	const handleYearChange = (e) => {
		const newYear = e.target.value;
		if (newYear !== 'null') {
			const newDate = new Date(date ? `${newYear}-${selectedMonth}-01` : `${newYear}-01-01`);
			setDate(newDate);
		} else {
			const newDate = date ? new Date(`1970-${selectedMonth}-01`) : null;
			setDate(newDate);
		}
	};

	return (
		<div style={{ display: 'flex', gap: 5 }}>
			<div className={'phaseSelectDateContainer'}>
				<select value={selectedDay} onChange={handleDayChange} className={'phaseSelectDate progressionInput'}>
					<option value="null">{t('translation.day')}</option>
					{days.map((day) => (
						<option key={day.value} value={day.value}>
							{day.name}
						</option>
					))}
				</select>
			</div>

			<div className={'phaseSelectDateContainer'}>
				<select
					value={selectedMonth}
					onChange={handleMonthChange}
					className={'phaseSelectDate progressionInput'}>
					<option value="null">{t('progression_page.no_month')}</option>
					{months.map((month) => (
						<option key={month.value} value={month.value}>
							{month.name}
						</option>
					))}
				</select>
			</div>

			<div className={'phaseSelectDateContainer'}>
				<select value={selectedYear} onChange={handleYearChange} className={'phaseSelectDate progressionInput'}>
					<option value="null">{t('progression_page.no_year')}</option>
					{years.map((year) => (
						<option key={year} value={year}>
							{year}
						</option>
					))}
				</select>
			</div>
		</div>
	);
};

export default MonthYearPicker;
